import { useQuery } from '@tanstack/react-query';

import { getOne } from '../get/getOne';
import { queryOptions } from '../options';
import type { OneQueryKey } from '../types/params';

/**
 *
 * @param key string, the query key
 * @param id string, the element id
 * @param options object, the query options
 *
 * @returns a query hook for the plain many-resource, to be used for non paginated resources.
 */
export function useGetOne<R, P = R>(
  key: string,
  id: string | number,
  options?: Parameters<typeof useQuery<R, Error, R, OneQueryKey>>[2] & {
    throwOnError?: boolean;
    dataParser?: (data: R) => P;
  },
) {
  const query = useQuery<R, Error, R, OneQueryKey>([key, id], getOne, {
    ...queryOptions,
    ...options,
  });

  if (query.isError && options?.throwOnError !== false) {
    throw new Error(query.error.message ?? 'Errore sconosciuto');
  }

  if (options?.dataParser && query.data) {
    return {
      ...query,
      data: options.dataParser(query.data),
    };
  }

  return query;
}
