import { useMemo } from 'react';

import { useGetMany } from '../../api/hooks/useGetMany';

import type { DomainItem, Domains } from './types';

export function useDomain<F>(
  domain: Domains,
  filters?: F,
  options?: Parameters<typeof useGetMany<F, DomainItem>>[2],
) {
  return useGetMany<F, DomainItem>(domain, filters, {
    staleTime: Infinity,
    ...options,
  });
}

export function useDomainOptions<F = Record<string, any> | undefined>(
  domain: Domains,
  filters?: F,
  queryOptions?: Parameters<typeof useGetMany<F, DomainItem>>[2],
) {
  const { data, ...rest } = useDomain<F>(domain, filters, queryOptions);

  const options = useMemo(
    () =>
      data?.map(({ id, nome, ...others }) => ({
        key: id,
        value: nome,
        data: others,
      })) ?? [],
    [data],
  );

  return { options, ...rest };
}
