import { AccountCircleTwoTone, NoAccountsTwoTone } from '@mui/icons-material';
import { Box, IconButton, ListItem, Tooltip } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import type { NuoviSoggettiFormValue } from '..';
import { CurrencyField } from '../../../../../../../form/fields/currency-field';
import { TextField } from '../../../../../../../form/fields/text-field';

export function NuovoSoggettoFormFields(props: { index: number }) {
  const { getValues, control } = useFormContext<NuoviSoggettiFormValue>();
  const soggetto = getValues(`soggetti.${props.index}`);

  const { update } = useFieldArray({
    control,
    name: 'soggetti',
  });

  const handleToggleExclude = useCallback(() => {
    const values = getValues();

    update(props.index, {
      ...values.soggetti[props.index],
      excluded: !values.soggetti[props.index].excluded,
    });
  }, [getValues, props.index, update]);

  const renderToggleExcludeIcon = useMemo(() => {
    if (soggetto.excluded) {
      return <AccountCircleTwoTone color="success" />;
    }

    return <NoAccountsTwoTone color="error" />;
  }, [soggetto.excluded]);

  return (
    <ListItem>
      <Box display="flex" gap={1} justifyContent="space-evenly" width="100%">
        <TextField
          disabled={soggetto.excluded}
          label="Nome"
          name={`soggetti.${props.index}.nome`}
          size="small"
        />
        <TextField
          disabled={soggetto.excluded}
          label="P. IVA"
          name={`soggetti.${props.index}.partitaiva`}
          size="small"
          validationKey="partitaiva"
        />
        <TextField
          disabled={soggetto.excluded}
          label="CF"
          name={`soggetti.${props.index}.codicefiscale`}
          size="small"
          validationKey="codicefiscale"
        />
        <CurrencyField
          disabled={soggetto.excluded}
          label="Importo"
          name={`soggetti.${props.index}.importo`}
          size="small"
        />
        <Tooltip
          title={`${soggetto.excluded ? 'Includi' : 'Escludi'} soggetto`}
        >
          <IconButton onClick={handleToggleExclude}>
            {renderToggleExcludeIcon}
          </IconButton>
        </Tooltip>
      </Box>
    </ListItem>
  );
}
