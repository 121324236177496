import { instance } from '../axios';

export async function postJson<T, R>({
  path,
  data,
}: {
  path: string;
  data: T;
}) {
  const response = await instance.post<R>(`${path}/`, data);

  return response;
}
