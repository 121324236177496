import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router';

import { useGetMany } from '../../../../api/hooks/useGetMany';
import { EmptyCard } from '../../../../common/components/empty-card';
import type { FascicoloMessaggio } from '../../../../types/messaggio';

import { Message } from './message';
import { NewMessage } from './new-message';

export function FascicoloMessages() {
  const { fascicolo } = useParams();
  const { data } = useGetMany<{}, FascicoloMessaggio>(
    `fascicolo/${fascicolo}/messaggio`,
  );

  const renderContent = useMemo(() => {
    if (data && data?.length === 0) {
      return (
        <EmptyCard sx={{ mb: 5 }}>
          <Typography variant="body1">Nessun messaggio da mostrare</Typography>
        </EmptyCard>
      );
    }

    return data?.map(message => (
      <Message
        key={message.id}
        contenuto={message.contenuto}
        creato={message.creato}
        operatore={message.operatore}
      />
    ));
  }, [data]);

  return (
    <Box>
      <Box
        sx={{
          maxHeight: 'calc(100vh - 420px)',
          overflow: 'hidden',
          overflowY: 'auto',
          pb: 2,
        }}
      >
        {renderContent}
      </Box>
      <NewMessage />
    </Box>
  );
}
