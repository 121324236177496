import { Tab } from '@mui/material';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import type { Params } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { useSubMenuContext } from '../../contexts/sub-menu-context';

import {
  tabIndicatorStyle,
  TabWrapper,
  Tabs,
  tabScrollButtonStyle,
} from './styles';

export function TabsNavigation() {
  const { pathname } = useLocation();
  const { menu } = useSubMenuContext();
  const params = useParams();

  const parsedMenu = useMemo(() => {
    if (!menu) return [];

    return menu
      .map(item => ({
        ...item,
        path: getPath(item?.path ?? '', params),
      }))
      .filter(item => item.path);
  }, [menu, params]);

  if (!parsedMenu.length) return null;

  const value = getPathIndex(
    parsedMenu.map(item => item.path!),
    pathname,
  );

  return (
    <Tabs
      TabIndicatorProps={{ sx: tabIndicatorStyle }}
      TabScrollButtonProps={{ sx: tabScrollButtonStyle }}
      scrollButtons="auto"
      sx={{ mb: 3 }}
      value={value}
      variant="scrollable"
    >
      {parsedMenu.map((item, i) => (
        <TabNavigationItem key={item.path} item={item} selected={i === value} />
      ))}
    </Tabs>
  );
}

function TabNavigationItem(props: {
  item: { path: string | null; label?: string };
  selected: boolean;
}) {
  if (!props.item.path) return null;

  return (
    <TabWrapper>
      <Tab
        className={props.selected ? 'Mui-selected' : ''}
        component={Link}
        label={props.item.label}
        sx={{ whiteSpace: 'nowrap' }}
        to={props.item.path}
      />
    </TabWrapper>
  );
}

function getPath(path: string, params: Readonly<Params<string>>) {
  const paths = path.split('/');
  const paramsPath = paths.filter(p => p.startsWith(':'));
  const isValidPath = paramsPath.every(p => params[p.replace(':', '')]);

  if (!isValidPath) {
    return null;
  }

  return paths
    .map(p => {
      if (p.startsWith(':')) {
        const collection = p.replace(':', '');

        return params[collection] ?? null;
      }

      return p;
    })
    .join('/');
}

function getPathIndex(parsedMenuPaths: string[], pathname: string) {
  return parsedMenuPaths.reduce((res, path, index) => {
    if (path && pathname.includes(path)) {
      // Check if the current path has the most specific metch withing the submenu items
      return path.split('/').length > parsedMenuPaths[res].split('/').length
        ? index
        : res;
    }

    return res;
  }, 0);
}
