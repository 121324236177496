import MuiAutocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import MuiTextField from '@mui/material/TextField';
import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { useAction } from '../../../../hooks/use-action';

export type OptionCellRendererProps<T> = {
  disabled?: boolean;
  index: number;
  name: string;
  field: string;
  options: T[];
  isLoading: boolean;
  multiple?: boolean;
  required?: boolean;
  resetOnChange?: string[];
  getOptionDisabled?: (option: any) => boolean;
  getOptionLabel: (option: T) => string;
  keyField: string;
};

export function OptionCellRenderer<T extends Record<string, any>>(
  props: OptionCellRendererProps<T>,
) {
  const { getValues, control } = useFormContext();

  const { isShow } = useAction();

  const itemInScope = getValues(props.name)?.[props.index];

  const { update } = useFieldArray({
    control,
    name: props.name,
  });

  const updateField = useCallback(
    (value: string | number | undefined | string[] | number[]) => {
      if (props.resetOnChange) {
        const reset = props.resetOnChange.reduce(
          (acc, field) => ({ ...acc, [field]: undefined }),
          {},
        );

        update(props.index, { ...itemInScope, [props.field]: value, ...reset });
      } else {
        update(props.index, { ...itemInScope, [props.field]: value });
      }
    },
    [itemInScope, props, update],
  );

  const handleUpdate = useCallback(
    (_: unknown, value: any) => {
      const newVal = props.multiple
        ? value.map((v: Record<string, any>) => v?.[props.keyField] ?? v)
        : value?.[props.keyField] ?? value;

      updateField(newVal);
    },
    [props.keyField, props.multiple, updateField],
  );

  // Modifica Diego da controllare
  let isActive = isShow || props.disabled;
  if (props.disabled !== undefined || props.disabled !== null)isActive = props.disabled;
  // Eventualmente rimuovere queste linee
  // e riprostinare:  disabled={isShow || props.isLoading || props.disabled}
  // Modifica Diego da controllare end

  return (
    <Box alignItems="center" display="flex">
      <MuiAutocomplete
        defaultValue={itemInScope?.[props.field]}
        disabled={isActive || props.isLoading}
        getOptionDisabled={props.getOptionDisabled}
        getOptionLabel={props.getOptionLabel}
        multiple={props.multiple}
        options={props.options}
        renderInput={params => (
          <MuiTextField
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              ...params.inputProps,
              autoComplete: 'disabled',
            }}
            required={
              props.required
            }
            value={itemInScope?.[props.field]}
            variant="standard"
            fullWidth
          />
        )}
        size="small"
        sx={{ display: 'flex', alignItems: 'center' }}
        fullWidth
        onChange={handleUpdate}
      />
    </Box>
  );
}
