import { Collapse, useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useMemo } from 'react';
import { matchRoutes, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import type { MenuItem } from '../../../../../configurations/menu/types';
import { useRoutes } from '../../../../../configurations/routes/useRoutes';
import { useAppDrawerContext } from '../../../../contexts/app-drawer-context';
import { useMenuContext } from '../../../../contexts/menu-context';
import useToggleState from '../../../../hooks/useToggleState';
import { FontIcon } from '../../../font-icon';

import { ListItemButton } from './styles';

export function SideMenu() {
  const { activeSection, menuItems } = useMenuContext();
  const items = useMemo(
    () => (activeSection ? activeSection.children : menuItems),
    [activeSection, menuItems],
  );

  return (
    <List sx={{ visibility: activeSection ? 'visible' : 'hidden' }}>
      {items?.map(menuItem => (
        <Item key={menuItem.path ?? menuItem.label} menuItem={menuItem} />
      ))}
    </List>
  );
}

function Item(props: { menuItem: MenuItem }) {
  const { setActiveMenuItem, activeMenuItem } = useMenuContext();
  const [expanded, toggleExpanded, toggleOn] = useToggleState(false);
  const location = useLocation();
  const theme = useTheme();
  const hasChildren = !!props.menuItem.children?.length;
  const hasPath = !!props.menuItem.path;
  const { open } = useAppDrawerContext();

  const routes = useRoutes();
  const matches = useMemo(
    () => matchRoutes(routes?.data ?? [], location.pathname),
    [location.pathname, routes?.data],
  );

  const isActive = useMemo(
    () =>
      matches?.[0].route.path === props.menuItem.path ||
      matches?.[1].pathname === `/${props.menuItem.path}`,
    [matches, props.menuItem.path],
  );

  const buttonProps = hasPath
    ? { component: Link, to: props.menuItem.path }
    : { onClick: toggleExpanded };

  useEffect(() => {
    if (isActive) {
      setActiveMenuItem(props.menuItem);
    }
  }, [location.pathname, isActive, props.menuItem, setActiveMenuItem]);

  useEffect(() => {
    if (
      hasChildren &&
      props.menuItem.children?.some(
        child =>
          matches?.[0].route.path === child.path ||
          matches?.[1].pathname === `/${child.path}`,
      )
    ) {
      toggleOn();
    }
  }, [toggleOn, hasChildren, props.menuItem.children, matches]);

  return (
    <>
      <ListItem>
        <ListItemButton
          {...buttonProps}
          selected={activeMenuItem?.path === props.menuItem.path || expanded}
        >
          <ListItemIcon sx={{ color: theme.palette.background.default }}>
            {props.menuItem.icon && (
              <FontIcon
                color={theme.palette.background.default}
                icon={props.menuItem.icon}
              />
            )}
          </ListItemIcon>
          <ListItemText
            primary={props.menuItem.label}
            sx={{ overflow: 'hidden', maxWidth: open ? 'unset' : 0 }}
          />
          {hasChildren && !hasPath && open && (
            <FontIcon
              color={theme.palette.background.default}
              icon={expanded ? 'expand_less' : 'expand_more'}
            />
          )}
        </ListItemButton>
      </ListItem>
      <SubMenu expanded={expanded} route={props.menuItem} />
    </>
  );
}

function SubMenu(props: { route: MenuItem; expanded: boolean }) {
  const { open } = useAppDrawerContext();
  const theme = useTheme();
  const hasChildren = !!props.route.children?.length;

  if (!hasChildren) return null;

  return (
    <Collapse in={props.expanded}>
      <List
        sx={{
          pl: open ? 1 : 0,
          backgroundColor: theme.palette.background.contrast,
        }}
        dense
      >
        {props.route.children?.map(child => (
          <Item key={child.path ?? child.label} menuItem={child} />
        ))}
      </List>
    </Collapse>
  );
}
