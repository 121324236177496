import { Box, LinearProgress } from '@mui/material';

import { Wrapper } from './styles';

export default function LoadingScreen() {
  return (
    <Box>
      <LinearProgress />
      <Wrapper />
    </Box>
  );
}
