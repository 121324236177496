import { useInfiniteQuery } from '@tanstack/react-query';

import { getPaginatedMany } from '../get/getPaginatedMany';
import { queryOptions } from '../options';
import type { PaginatedQueryKey, Sort } from '../types/params';

type Callback<R> = (params: any) => Promise<{
  showFilters?: boolean;
  totale?: number;
  risultati: R[];
}>;

/**
 *
 * @param key string, the query key
 * @param filters object, the query filters
 * @param sort object, the query sort
 * @param page number, the query cursor
 * @param limit number, the query limit
 * @param options object, the query options
 *
 * @returns a query hook for the paginated many-resource, to be used for paginated resources, not ag-grid infinite (example: long selects or autocompletes).
 */

export function useGetPaginatedMany<F extends any, R>(
  key: string,
  filters?: F,
  sort?: Sort,
  page?: number,
  limit?: number,
  options?: Partial<typeof queryOptions> & { enabled?: boolean },
) {
  const query = useInfiniteQuery<
    Awaited<ReturnType<Callback<R>>>,
    Error,
    Awaited<ReturnType<Callback<R>>>,
    PaginatedQueryKey<F>
  >([key, filters, sort, page, limit], getPaginatedMany, {
    getNextPageParam: (lastPage, pages) => {
      const totalLoaded = pages.reduce(
        (tot, pag) => tot + pag.risultati.length,
        0,
      );

      if (Number(totalLoaded) < Number(lastPage.totale)) {
        return pages.length;
      }

      return false;
    },
    getPreviousPageParam: (lastPage, pages) =>
      pages.length > 1 ? pages.length - 1 : false,
    ...queryOptions,
    ...options,
  });

  if (query.isError) {
    throw new Error(query.error.message ?? 'Errore sconosciuto');
  }

  return query;
}
