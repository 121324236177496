import { ListItem, Divider } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import type { FascicoloFile } from '../../../../../types/fascicolo-file';
import { FileActions } from '../../../file/file-actions';
import { FileIcon } from '../file-icon';

export function FileItem(props: { file: FascicoloFile }) {
  return (
    <>
      <ListItem sx={{ position: 'static' }} disablePadding>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <FileIcon file={props.file} />
        </ListItemIcon>
        <ListItemText
          primary={props.file.descrizione}
          secondary={props.file.fascicolofilecategoria}
        />
        <FileActions
          file={props.file}
          previewProps={{ disablePortal: true }}
          download
          preview
        />
      </ListItem>
      <Divider component="li" variant="inset" />
    </>
  );
}
