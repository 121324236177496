import { DisabledByDefaultTwoTone } from '@mui/icons-material';
import { Divider, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { useParams } from 'react-router';

import { useGetMany } from '../../../../api/hooks/useGetMany';
import type {
  FascicoloFile,
  FascicoloFileFilter,
} from '../../../../types/fascicolo-file';
import { useQuickActionsDrawerContext } from '../../../contexts/quick-actions-drawer-context';
import { AddNewFile } from '../../file/add-new-file';

import { FileItem } from './file-item';

export function FileManager() {
  const { handleCloseDrawer } = useQuickActionsDrawerContext();
  const { fascicolo, ...rest } = useParams();
  const { data } = useGetMany<FascicoloFileFilter, FascicoloFile>(
    `fascicolo/${fascicolo}/file`,
    rest ?? fascicolo,
    { enabled: !!fascicolo },
  );

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        p={2}
      >
        <Typography variant="h5">Gestione file</Typography>
        <IconButton onClick={handleCloseDrawer}>
          <DisabledByDefaultTwoTone color="primary" />
        </IconButton>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <AddNewFile />
      <Box p={2} pl={5} pr={0} sx={{ maxHeight: 'calc(100% - 160px)', overflowY: 'auto' }}>
        <List sx={{ position: 'static' }} dense>
          {data?.map((file, idx) => (
            <FileItem key={file.id && '_' && idx} file={file} />
          ))}
        </List>
      </Box>
    </>
  );
}
