import type { QueryFunctionContext } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import decode from 'jwt-decode';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';

import { instance } from '../../api/axios';
import { queryOptions } from '../../api/options';

type DecodedToken = {
  user_name: string;
  exp: number;
  clienti: number[];
  is_admin: boolean;
  user_id: string;
};

type TokenResponse = {
  access: string;
  refresh: string;
};

type CreateTokenPayload =
  | { username: string; password: string }
  | { refresh: string };

async function getToken({
  queryKey: [key, payload],
}: QueryFunctionContext<readonly [string, CreateTokenPayload]>) {
  try {
    const { data } = await instance.post<TokenResponse>(key, payload);

    return data;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
}

function useGetToken(params: CreateTokenPayload) {
  const enabled =
    'refresh' in params
      ? !!params.refresh
      : !!params.password && !!params.username;

  return useQuery<
    TokenResponse,
    Error,
    TokenResponse,
    [string, CreateTokenPayload]
  >(
    [`/auth/jwt/${'refresh' in params ? 'refresh' : 'create'}/`, params],
    getToken,
    {
      ...queryOptions,
      staleTime: Infinity,
      enabled,
      retry: 'refresh' in params ? 10 : 0,
      retryDelay: 1000,
    },
  );
}

export function useToken() {
  const location = useLocation();

  const timeout = useRef<any>();
  const [params, setParams] = useState<CreateTokenPayload>({
    username: '',
    password: '',
  });

  const { data, isError, isSuccess, fetchStatus, refetch } =
    useGetToken(params);

  const [token, setToken] = useLocalStorage<string | null>('token', null);
  const [clients, setClients] = useLocalStorage<number[] | null>(
    'clients',
    null,
  );
  const [refresh, setRefresh] = useLocalStorage<string | null>('refresh', null);
  const [expires, setExpires] = useLocalStorage<string | null>('expires', null);
  const [userId, setUserId] = useLocalStorage<string | null>('userId', null);
  const [username, setUsername] = useLocalStorage<string | null>(
    'username',
    null,
  );
  const [isAdmin, setIsAdmin] = useLocalStorage('isAdmin', false);

  const [selectedClient, setSelectedClient] = useLocalStorage<null | number>(
    'selectedClient',
    null,
  );

  const logout = useCallback(() => {
    setToken(null);
    setRefresh(null);
    setExpires(null);
    setUserId(null);
    setIsAdmin(false);
    setClients(null);
    setUsername(null);
    setSelectedClient(null);

    // Remove all persistent filters
    Object.keys(localStorage).forEach(
      key => key.startsWith('filter:') && localStorage.removeItem(key),
    );

    if (location.pathname !== '/') {
      window.location.pathname = '/';
    }
  }, [
    location.pathname,
    setClients,
    setExpires,
    setIsAdmin,
    setRefresh,
    setToken,
    setUserId,
    setUsername,
    setSelectedClient,
  ]);

  const createToken = useCallback(
    (newParams: { username: string; password: string }) => {
      if (JSON.stringify(newParams) === JSON.stringify(params)) {
        refetch();

        return;
      }
      setParams(newParams);
    },
    [params, refetch],
  );

  const refreshAccessToken = useCallback(() => {
    console.warn('Refresh token');
    if (refresh) {
      // If the params already contain a refresh token it mean it's a second refresh and we don't need to update the params
      if (refresh === (params as { refresh: string }).refresh) {
        refetch();
      } else {
        // If not, then it means params is still containing user/password, setting the new params will automatically trigger a refresh, this is the first refresh
        setParams({ refresh });
      }
    } else {
      console.warn('Could not refresh token');
      logout();
    }
  }, [logout, refresh, params, refetch]);

  useEffect(() => {
    if (isSuccess && data?.access) {
      const {
        exp,
        clienti,
        is_admin: isAdminUser,
        user_name: userName,
        user_id: id,
      } = decode<DecodedToken>(data.access);

      setIsAdmin(isAdminUser);
      setUsername(userName);
      setClients(clienti);
      setToken(data.access);
      setUserId(id);
      setExpires((Number(exp) * 1000).toString());

      if (data.refresh) {
        setRefresh(data.refresh);
      }
    }
  }, [
    data,
    isSuccess,
    setClients,
    setExpires,
    setIsAdmin,
    setRefresh,
    setToken,
    setUserId,
    setUsername,
  ]);

  useEffect(() => {
    if (isError) {
      console.warn('Error fetching token');
      logout();
    }
  }, [isError, logout]);

  useEffect(() => {
    const now = Date.now();
    const diff = Number(expires) - now;
    const offset = 1000 * 60 * 5;

    if (expires && Number(expires) - now <= 0) {
      console.warn('Token expiration is passed');
      logout();
    }

    if (expires) {
      timeout.current = setTimeout(refreshAccessToken, diff - offset);
    }

    return () => clearTimeout(timeout.current);
  }, [expires, logout, refreshAccessToken]);

  return {
    token,
    logout,
    clients,
    selectedClient,
    setSelectedClient,
    isAdmin,
    userId,
    username,
    createToken,
    isError,
    isSuccess,
    isLoading: fetchStatus === 'fetching',
  } as const;
}
