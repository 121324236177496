import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import { useAppDrawerContext } from '../../../contexts/app-drawer-context';
import { useAuthContext } from '../../../contexts/auth-context';
import { Breadcrumbs } from '../breadcrumb';

import { AccountMenu } from './account-menu';
import { DrawerHandle } from './drawer-handle';
import { InfoMenu } from './info-menu';
import { MainMenu } from './main-menu';
import { NotificationMenu } from './notification-menu';
import { AppBarWrapper } from './wrapper';

export function AppBar() {
  const { open } = useAppDrawerContext();
  const { isAdmin } = useAuthContext();

  return (
    <AppBarWrapper elevation={4} open={open} position="fixed">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row">
          <DrawerHandle />
          <Breadcrumbs />
        </Stack>
        <Box alignItems="center" display="flex">
          <MainMenu />
          {!isAdmin && <NotificationMenu />}
          <InfoMenu />
          <AccountMenu />
        </Box>
      </Toolbar>
    </AppBarWrapper>
  );
}
