import { PolicyTwoTone } from '@mui/icons-material';
import { Divider, ListItem, TextField } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';

import { useGetAllPages } from '../../../../../../api/hooks/useGetAllPages';
import type { Client } from '../../../../../../types/cliente';
import { useAuthContext } from '../../../../../contexts/auth-context';

export function SelectClient() {
  const { clients, isAdmin, setSelectedClient, selectedClient } =
    useAuthContext();

  const { data, isLoading } = useGetAllPages<Client>('cliente');

  const clientsMap = useMemo(
    () =>
      data?.reduce(
        (acc2: Record<number, Client>, client) => ({
          ...acc2,
          [client.id]: client,
        }),
        {},
      ) ?? {},
    [data],
  );

  const handleChangeClient = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = event.target;
      setSelectedClient(value === '' ? null : parseInt(value, 10));

      window.location.href = '/';
    },
    [setSelectedClient],
  );

  if (!clients?.length || !isAdmin) {
    return null;
  }

  return (
    <>
      <Divider />
      <ListItem sx={{ width: 300, pt: 2 }}>
        <ListItemIcon>
          <PolicyTwoTone fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <TextField
            disabled={isLoading}
            label="Cliente"
            size="small"
            value={selectedClient ?? ''}
            fullWidth
            select
            onChange={handleChangeClient}
          >
            <MenuItem value="">-- Nessuno --</MenuItem>
            {clients?.map(client => (
              <MenuItem key={client} value={client}>
                {clientsMap[client]?.ragione_sociale ?? client}
              </MenuItem>
            ))}
          </TextField>
        </ListItemText>
      </ListItem>
    </>
  );
}
