import type {
  ColDef,
  HeaderValueGetterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';

import { readable } from '../../../../../utils/string';

export function headerValueGetter(params: HeaderValueGetterParams) {
  return (
    params.colDef.headerName ?? readable((params.colDef as ColDef)?.field ?? '')
  );
}
