import type { Theme } from '@mui/material';

export function authenticateCSS(theme: Theme) {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  };
}
