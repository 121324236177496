import { Box, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import type { ArrayElement, Fideiussioni, HeaderElement } from '../../../../../../../types/fascicolo-file';
import { currency } from '../../../../../../../utils/currency';
import styles from '../../../../../summary-table/styles.module.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, react/no-unused-prop-types
export function FideiussioniContent(props: { fideiussioni: Fideiussioni }) {
  const rendeHeader = useMemo(() => props.fideiussioni.header.map((value: HeaderElement, idx: number) => (
    <th key={`th_${idx.toString()}`}>
      {value.name || ''}
    </th>
  )), [props]);

  const renderBody = useMemo(() => {
    if (!props.fideiussioni.array || props.fideiussioni.array.length === 0) {
      return (
        <tr>
          <td colSpan={props.fideiussioni.header.length}>Nessun risultato!</td>
        </tr>
      );
    }

    return props.fideiussioni.array.map((value: any, idx: number) => (
      <tr key={`body_${idx.toString()}`} style={{ background: idx % 2 ? '#f6f6f6' : 'trasparent' }}>
        { value.map((name: ArrayElement, index: number) => (
          <td key={`td_${index.toString()}`} style={{ whiteSpace: props.fideiussioni.header[index].type === 'currency' ? 'nowrap' : 'inherit', textAlign: props.fideiussioni.header[index].type === 'currency' ? 'right' : 'center' }}>
            { returnType(name, props.fideiussioni.header[index].type) }
          </td>
        ))}
      </tr>
    ));
  }, [props]);

  return (
    <Paper sx={{ mt: 2 }} variant="outlined">
      <Typography sx={{ p: 2 }} variant="h5">
        <b>{props.fideiussioni.title}</b>
      </Typography>
      <Box padding="5px 5px" style={{ overflowX: 'auto' }} width="100%">
        <table className={styles.table}>
          <thead>
            <tr>
              {rendeHeader}
            </tr>
          </thead>
          <tbody>
            {renderBody}
          </tbody>
        </table>
      </Box>
    </Paper>
  );
}

function returnType(value: any, type: string) {
  if (type === 'currency') return value ? currency(value) : '';
  if (type === 'date') return dayjs(value as Date).isValid() ? dayjs(value as Date).format('DD/MM/YYYY') : '';

  return value;
}
