import type { Theme } from '@mui/material';
import { alpha, Button, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { MenuItem } from '../../../../../configurations/menu/types';
import { useMenus } from '../../../../../configurations/menu/useMenus';
import { useMenuContext } from '../../../../contexts/menu-context';
import { FontIcon } from '../../../font-icon';

export function MainMenu() {
  const { data } = useMenus();
  const menuItems = useMemo(() => data?.menu ?? [], [data]);

  return (
    <Box alignItems="center" display="flex" gap={2}>
      {menuItems?.map(menuItem => (
        <MainMenuItem key={menuItem.label} menuItem={menuItem} />
      ))}
      <Divider orientation="vertical" flexItem />
    </Box>
  );
}

function MainMenuItem(props: { menuItem: MenuItem }) {
  const { setActiveSection, activeSection, activeMenuItem } = useMenuContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const isActive = activeSection === props.menuItem;

  const icon = useMemo(
    () =>
      props.menuItem.icon && (
        <FontIcon
          color={
            isActive ? theme.palette.text.contrast : theme.palette.primary.main
          }
          icon={props.menuItem.icon}
        />
      ),
    [props.menuItem.icon, isActive, theme],
  );

  const handleClick = useCallback(() => {
    setActiveSection(props.menuItem);

    if (props.menuItem.path !== undefined) {
      navigate(props.menuItem.path);
    }
  }, [navigate, props.menuItem, setActiveSection]);

  useEffect(() => {
    if (!activeSection && activeMenuItem) {
      const active = activeMenuItem.section === props.menuItem.section;

      if (active && props.menuItem) {
        setActiveSection(props.menuItem);
      }
    }
  }, [activeMenuItem, activeSection, props.menuItem, setActiveSection]);

  return (
    <Button
      startIcon={icon}
      sx={isActive ? getActiveStyle : undefined}
      variant={activeSection === props.menuItem ? 'contained' : 'text'}
      onClick={handleClick}
    >
      {props.menuItem.label}
    </Button>
  );
}

function getActiveStyle(theme: Theme) {
  return {
    boxShadow: `0px 0px 0px 1px inset ${alpha(
      theme.palette.primary.dark,
      0.5,
    )}`,
  };
}
