import Box from '@mui/material/Box';
import type { ComponentProps } from 'react';

type GridProps = ComponentProps<typeof Box> & {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
};

export function Grid({ xs = 1, sm = 3, md = 3, lg = 3, ...props }: GridProps) {
  return (
    <Box
      alignItems="center"
      display="grid"
      gap={2}
      gridTemplateColumns={{
        xs: `repeat(${xs}, 1fr)`,
        sm: `repeat(${sm}, 1fr)`,
        md: `repeat(${md}, 1fr)`,
        lg: `repeat(${lg}, 1fr)`,
      }}
      p={2}
      {...props}
    />
  );
}
