import MuiTextField from '@mui/material/TextField';
import type { ComponentProps } from 'react';
import type { FieldValues, ValidationRule } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import type { UseFormRegister } from 'react-hook-form/dist/types/form';

import { useInputValidation } from '../../../../../configurations/validations';
import { useFieldError } from '../../hooks/useFieldError';
import { useFormFieldsContext } from '../../index';

type TextFieldProps<TFieldValues extends FieldValues> = ComponentProps<
  typeof MuiTextField
> & {
  name: string;
  pattern?: ValidationRule<RegExp>;
  active_pattern? : string;
  register?: UseFormRegister<TFieldValues>;
  validationKey?: string;
};

export function TextField<TFieldValues extends FieldValues>(
  props: TextFieldProps<TFieldValues>,
) {
  const fieldContext = useFormFieldsContext();
  const { control, trigger } = useFormContext();
  const error = useFieldError(props.name);

  const validations = useInputValidation({
    options: { enabled: props.active_pattern !== 'false' },
  });
  const pattern = validations?.[props.validationKey ?? props.name];

  return (
    <Controller
      control={control}
      defaultValue=""
      name={props.name}
      render={({ field }) => (
        <MuiTextField
          error={!!error}
          helperText={error}
          label={props.label}
          fullWidth
          {...fieldContext}
          {...field}
          {...props}
          onBlur={() => {
            trigger(props.name);
          }}
        />
      )}
      rules={{
        required: props.required,
        pattern: props.active_pattern === 'false' ? undefined : props.pattern ?? pattern,
      }}
    />
  );
}
