import { useQuery } from '@tanstack/react-query';

import { getMany } from '../get/getMany';
import { queryOptions } from '../options';
import type { ManyQueryKey } from '../types/params';

/**
 *
 * @param key string, the query key
 * @param filters object, the query filters
 * @param options object, the query options
 *
 * @returns a query hook for the plain many-resource, to be used for non paginated resources.
 */
export function useGetMany<F, R>(
  key: string,
  filters?: F,
  options?: Parameters<typeof useQuery<R[], Error, R[], ManyQueryKey<F>>>[2],
) {
  const query = useQuery<R[], Error, R[], ManyQueryKey<F>>(
    [key, filters],
    getMany,
    {
      ...queryOptions,
      ...options,
    },
  );

  if (query.isError) {
    throw new Error(query.error.message ?? 'Errore sconosciuto');
  }

  return query;
}
