import type { FileElaborato } from '../../../../../../../types/fascicolo-file';
import { Form } from '../../../../../form';
import { CheckboxField } from '../../../../../form/fields/checkbox-field';

export function AdditionalRequest(props: { file: FileElaborato, onCheckValue: any}) {
  if (props.file.link_altraprocedura) return null;

  return (
    <Form onSubmit={() => null}>
      <CheckboxField label="Soggetto Creditore Terzo" name="soggetto_creditore_terzo" onClick={(event: any) => props.onCheckValue(event.target.value)} />
    </Form>
  );
}
