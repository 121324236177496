import type { FascicoloFilePost, FilesDetails } from '../../../../types/fascicolo-file';

export function parseFascicoloFile(values: FascicoloFilePost) {
  const formData = new FormData();

  const files = values.filesDetails?.map(({ file }) => file, []);
  const fileData = values.filesDetails?.reduce(
    (acc: Record<string, Omit<FilesDetails, 'file'>>, curr) => ({
      ...acc,
      [curr.filename]: {
        fascicolofilecategoria: curr.fascicolofilecategoria,
        descrizione: curr.descrizione,
        filename: curr.filename,
      },
    }),
    {},
  );

  const { filesDetails, ...rest } = values;

  Object.keys(rest).forEach(key => {
    if (rest[key as keyof typeof rest] !== undefined) {
      formData.append(key, rest[key as keyof typeof rest]?.toString() ?? '');
    }
  });

  files?.forEach((file: File, i: number) => {
    formData.append(`files[${i}]`, file);
  });

  formData.append('files_data', JSON.stringify(fileData));

  return formData;
}
