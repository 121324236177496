import type { DrawerProps, Theme } from '@mui/material';
import { styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import type { CSSObject } from '@mui/material/styles';

const transitionMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const mixin = (theme: Theme, isDragging: boolean): CSSObject => ({
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
  marginTop: 63,
  overflowX: 'hidden',
  ...(!isDragging && { ...transitionMixin(theme) }),
});

export const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: prop =>
    prop !== 'open' && prop !== 'width' && prop !== 'isDragging',
})<DrawerProps & { theme?: Theme; width: number; isDragging: boolean }>(
  ({ theme, open, width, isDragging }) => ({
    width,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    ...(open && {
      ...mixin(theme, isDragging),
      width,
      '& .MuiDrawer-paper': {
        backgroundColor: theme.palette.background.light,
        ...mixin(theme, isDragging),
        width,
      },
    }),
    ...(!open && {
      ...mixin(theme, isDragging),
      width: 0,
      '& .MuiDrawer-paper': {
        backgroundColor: theme.palette.background.light,
        ...mixin(theme, isDragging),
        width: 0,
      },
    }),
  }),
);
