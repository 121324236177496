import { createTheme } from '@mui/material';

import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';

export const theme = createTheme({
  typography,
  palette,
  shadows,
  spacing: 6,
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          width: '8px',
          cursor: 'auto',
        },
        '*::-webkit-scrollbar-track': {
          background: 'trasparent',
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#ccccccdb',
          borderRadius: '2px',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          background: '#CCC',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        component: 'div',
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 4,
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h5',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root.MuiChip-root': {
            height: 25,
            margin: '3px 3px 3px 0',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          '&.MuiDivider-light': {
            borderColor: palette?.background?.default,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(3px)',
          zIndex: 1,
        },
      },
    },
  },
});
