import { EmptyCard } from '../../../../empty-card';

export type PreviewErrorProps = { error?: string | null };
export function PreviewError(props: PreviewErrorProps) {
  if (!props.error) return null;

  return (
    <EmptyCard sx={{ p: 3 }}>
      Oops! Non è stato possibile visualizzare il file. 😨
      <pre>{props.error}</pre>
    </EmptyCard>
  );
}
