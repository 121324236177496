import { object, ref, string } from 'yup';

export const schema = (validations: {
  [p: string]: { value: RegExp; message: string };
}) =>
  object({
    password: string()
      .required('Campo obbligatorio')
      .matches(validations?.password?.value, validations?.password?.message),
    ri_password: string()
      .required('Campo obbligatorio')
      .oneOf([ref('password'), null], 'Le password non coincidono'),
  });
