import type MuiTextField from '@mui/material/TextField';
import type { ComponentProps } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import type { UseFormRegister } from 'react-hook-form/dist/types/form';

import { useFieldError } from '../../hooks/useFieldError';
import { useFormFieldsContext } from '../../index';

import CurrencyInput from './currency-input';

type TextFieldProps<TFieldValues extends FieldValues> = ComponentProps<
  typeof MuiTextField
> & {
  name: string;
  register?: UseFormRegister<TFieldValues>;
};

export function CurrencyField<TFieldValues extends FieldValues>(
  props: TextFieldProps<TFieldValues>,
) {
  const fieldContext = useFormFieldsContext();
  const { control, trigger } = useFormContext();
  const error = useFieldError(props.name);

  return (
    <Controller
      control={control}
      defaultValue={null}
      name={props.name}
      render={({ field }) => (
        <CurrencyInput
          error={!!error}
          helperText={error}
          label={props.label}
          fullWidth
          {...fieldContext}
          {...field}
          {...props}
          onBlur={() => {
            trigger(props.name);
          }}
        />
      )}
      rules={{ required: props.required }}
    />
  );
}
