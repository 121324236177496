import type { NonEmptyArray } from './types';

/**
 * Type guard that verifies if the array is non-empty
 *
 * @param value the array to check
 */

export function isNonEmpty<T>(value: T[]): value is NonEmptyArray<T> {
  return value.length > 0;
}

/**
 * Returns an array with the specified length by applying the callback function successively. The return value of the
 * callback function is used to set the value at the nth position in the array.
 *
 * @param count the length of the resultant array that is being created
 * @param callbackfn the function to apply to create the array entries
 */
export function times<T>(count: number, callbackfn: (index: number) => T): T[] {
  return new Array(count).fill(undefined).map((_, i) => callbackfn(i));
}

/**
 * Returns an array of numbers representing the `id` property of each element in the given array.
 *
 * @template T
 * @param {T[]} [array=[]] The array of objects from which to extract the `id` property.
 * @returns {number[]} An array of numbers representing the `id` property of each element in the given array.
 */
export function toIdArray<T extends { id?: number | null }>(
  array: T[] = [],
): number[] {
  return array.map(item => item.id).filter(Boolean) as number[];
}

/**
 * Returns an array of objects with the `id` property set to the corresponding element of the given array.
 *
 * @param {number[]} [array=[]] The array of numbers to convert to an array of objects.
 * @returns {{ id: number | null }[]} An array of objects with the `id` property set to the corresponding element of the given array.
 */
export function toIdObject(array: number[] = []): { id: number | null }[] {
  return array.map(id => ({ id }));
}
