import { useLocation } from 'react-router';

export enum Endpoints {
  SHOW = 'show',
  LIST = 'list',
  ADD = 'add',
  UPDATE = 'update',
}

const actions = Object.values(Endpoints);
export type Action = typeof actions[number];

export function useAction() {
  const { pathname } = useLocation();
  const action = [...pathname.split('/')].pop() as Endpoints;

  return {
    action,
    isActionEndpoint: isActionEndpoint(action),
    isShow: action === Endpoints.SHOW,
    isList: action === Endpoints.LIST,
    isAdd: action === Endpoints.ADD,
    isUpdate: action === Endpoints.UPDATE,
  };
}

function isActionEndpoint(action: Endpoints): action is Endpoints {
  return actions.includes(action);
}
