import { instance } from '../axios';

export async function putJson<R>({
  id,
  path,
  data,
}: {
  id?: string | number;
  path: string;
  data: R;
}) {
  return instance.put(`${[path, id].filter(Boolean).join('/')}/`, data);
}
