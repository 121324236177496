import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import { AppDrawerContextProvider } from '../../contexts/app-drawer-context';
import { MenuContextProvider } from '../../contexts/menu-context';
import { QuickActionsContextProvider } from '../../contexts/quick-actions-drawer-context';
import { ResizableDrawerContextProvider } from '../../contexts/resizable-drawer-context';
import { SubMenuContextProvider } from '../../contexts/sub-menu-context';
import { ResizableDrawer } from '../resizable-drawer';

import { AppBar } from './app-bar';
import { Drawer } from './drawer';
import { Main } from './main';

export default function Layout() {
  return (
    <AppDrawerContextProvider>
      <ResizableDrawerContextProvider>
        <QuickActionsContextProvider>
          <Box display="flex">
            <MenuContextProvider>
              <AppBar />
              <Drawer />
              <SubMenuContextProvider>
                <Main>
                  <Outlet />
                </Main>
              </SubMenuContextProvider>
              <ResizableDrawer />
            </MenuContextProvider>
          </Box>
        </QuickActionsContextProvider>
      </ResizableDrawerContextProvider>
    </AppDrawerContextProvider>
  );
}
