import { css } from '@emotion/react';

export const iconsCSS = css`
  @font-face {
    font-family: 'Material Icons Two Tone';
    font-style: normal;
    font-weight: 400;
    src: url(/assets/icons-two-tones.woff2) format('woff2');
  }
  body {
    --google-font-color-materialiconstwotone: none;
  }

  .material-icons-two-tone {
    font-family: 'Material Icons Two Tone';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
`;
