import type { QueryFunctionContext } from '@tanstack/react-query';

import { instance } from '../axios';
import type { OneQueryKey } from '../types/params';

export async function getOne<R>({
  queryKey: [key, id],
}: QueryFunctionContext<OneQueryKey>) {
  const url = [key, id].filter(Boolean).join('/');
  const { data } = await instance.get<R>(`${url}`);

  return data;
}
