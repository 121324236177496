import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';

import { useAuthContext } from '../../../../../contexts/auth-context';
import useIdleTimeout from '../../../../../hooks/use-idle-timeout';
import useToggleState from '../../../../../hooks/useToggleState';

export function IdleModal() {
  const [open, toggle] = useToggleState(false);
  const { logout } = useAuthContext();

  const { idleTimer } = useIdleTimeout({
    onIdle: toggle,
    idleTime: 1500,
    promptTime: 300,
  });

  const handleStay = useCallback(() => {
    idleTimer.reset();
    toggle();
  }, [idleTimer, toggle]);

  return (
    <Dialog open={open}>
      <DialogTitle>⏳Sembri inattivo già da un po'...</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Non sembri essere più attivo sulla pagina da diverso tempo, per motivi
          di sicurezza sarai disconnesso tra pochi minuti.
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={logout}>
          Disconnettimi ora
        </Button>
        <Button variant="contained" onClick={handleStay}>
          Rimani connesso
        </Button>
      </DialogActions>
    </Dialog>
  );
}
