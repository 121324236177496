import { useMemo } from 'react';

import type { queryOptions } from '../options';

import { useGetPaginatedMany } from './useGetPaginatedMany';

export function useGetAllPages<T = Record<string, any>, F = any>(
  source: string,
  filters?: F,
  options?: Partial<typeof queryOptions>,
) {
  const { data, ...rest } = useGetPaginatedMany<F, T>(
    source,
    filters,
    undefined,
    -1,
    undefined,
    options,
  );

  const result = useMemo(
    () =>
      data?.pages.reduce((acc: T[], page) => [...acc, ...page.risultati], []) ??
      [],
    [data],
  );

  return {
    data: result,
    ...rest,
  };
}
