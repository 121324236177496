import type { ICellRendererParams } from 'ag-grid-community';

import type { RowActionsProps } from '../../../row-actions';
import { RowActions } from '../../../row-actions';

export function optionsColumnPartial(props?: Omit<RowActionsProps, 'params'>) {
  const defaultActions = { view: true, update: true };

  const getWidth = (): number => {
    if (!props) {
      return 120;
    }

    let actions = 0;
    if (props.view) {
      actions += 1;
    }
    if (props.update) {
      actions += 1;
    }
    if (props.delete) {
      actions += 1;
    }
    if (props.customActions) {
      actions += props.customActions.length;
    }

    return 60 * actions + 10;
  };

  return {
    headerName: '',
    field: '_opzioni',
    resizable: false,
    maxWidth: getWidth(),
    minWidth: getWidth(),
    cellRenderer: (params: ICellRendererParams) => (
      <RowActions params={params} {...(props || defaultActions)} />
    ),
  };
}
