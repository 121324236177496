import { DragIndicator } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useCallback } from 'react';

import { useResizableDrawerContext } from '../../../contexts/resizable-drawer-context';

export function ResizeHandle(props: {
  onDragStart: () => void;
  onDragEnd: () => void;
}) {
  const { setWidth } = useResizableDrawerContext();

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      const newWidth = window.innerWidth - event.clientX;
      const maxWindow = window.innerWidth / 2;
      if (newWidth > 300 && newWidth < maxWindow) {
        setWidth(newWidth);
      }
    },
    [setWidth],
  );

  const handleMouseUp = useCallback(() => {
    props.onDragEnd();
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove, props]);

  const handleMouseDown = useCallback(() => {
    props.onDragStart();
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove, handleMouseUp, props]);

  return (
    <Box
      sx={{
        userSelect: 'none',
        position: 'absolute',
        transform: 'translate(-15px, -50%)',
        top: '50%',
        zIndex: 3,
      }}
      onMouseDown={handleMouseDown}
    >
      <Button
        color="primary"
        size="small"
        sx={{
          cursor: 'col-resize',
          minWidth: 0,
          padding: '10px 2px 10px 13px',
        }}
        variant="contained"
      >
        <DragIndicator />
      </Button>
    </Box>
  );
}
