import { DisabledByDefaultTwoTone } from '@mui/icons-material';
import { Divider, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { FascicoloMessages } from '../../../../pages/fascicolo/form/messages';
import { useQuickActionsDrawerContext } from '../../../contexts/quick-actions-drawer-context';

export function Messages() {
  const { handleCloseDrawer } = useQuickActionsDrawerContext();

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        p={2}
      >
        <Typography variant="h5">Messaggi</Typography>
        <IconButton onClick={handleCloseDrawer}>
          <DisabledByDefaultTwoTone color="primary" />
        </IconButton>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box p={2} pl={5} pr={0} sx={{ overflowY: 'auto' }}>
        <FascicoloMessages />
      </Box>
    </>
  );
}
