import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import type { ComponentProps } from 'react';
import { useCallback } from 'react';
import type {
  ControllerRenderProps,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

import { useFieldError } from '../../hooks/useFieldError';
import { useFormFieldsContext } from '../../index';

type CheckboxFieldProps<TFieldValues extends FieldValues> = ComponentProps<
  typeof Checkbox
> &
  Pick<ComponentProps<typeof FormControlLabel>, 'label'> & {
    name: string;
    register?: UseFormRegister<TFieldValues>;
  };

export function CheckboxField<TFieldValues extends FieldValues>(
  props: CheckboxFieldProps<TFieldValues>,
) {
  const fieldContext = useFormFieldsContext();
  const error = useFieldError(props.name);
  const { control } = useFormContext();

  const renderCheckbox = useCallback(
    (field: ControllerRenderProps) => (
      <Checkbox
        {...fieldContext}
        {...field}
        {...props}
        checked={!!field.value}
      />
    ),
    [fieldContext, props],
  );

  return (
    <Controller
      control={control}
      defaultValue={props.defaultValue === 'true'}
      name={props.name}
      render={({ field }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={renderCheckbox(field)}
            label={`${props.label}${props.required ? ' *' : ''}`}
          />
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
      rules={{ required: props.required }}
    />
  );
}
