import { alpha, styled } from '@mui/material';
import ListItemButtonBase from '@mui/material/ListItemButton';

export const ListItemButton = styled(ListItemButtonBase)(({ theme }) => ({
  paddingLeft: 12,
  paddingRight: 12,
  '&.Mui-selected, :hover': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.background.light, 0.1),
  },
}));
