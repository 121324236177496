import type { QueryFunctionContext } from '@tanstack/react-query';

import { instance } from '../axios';
import type { PaginatedQueryKey } from '../types/params';

type Response<R> = {
  data: {
    risultati: R[];
    totale?: number;
    pagina?: number;
    show_filters?: boolean;
  };
};

export async function getPaginatedMany<R, F>({
  queryKey: [key, filter, sort, _page = 0, _limit = 10],
  pageParam,
}: QueryFunctionContext<PaginatedQueryKey<F>, number>) {
  const page = pageParam ?? _page;
  // eslint-disable-next-line no-underscore-dangle
  const _start = page * _limit;
  // eslint-disable-next-line no-underscore-dangle
  const _end = _start + _limit;

  const paginationParams = _page === -1 ? { _start: -1 } : { _start, _end };

  try {
    const {
      data: { risultati, totale, pagina, show_filters: showFilters },
    } = await instance.get<R, Response<R>>(`${key}/`, {
      params: {
        ...paginationParams,
        ...filter,
        ...sort,
      },
    });

    return { risultati, totale, pagina, showFilters };
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message);
    }
    throw new Error('Errore sconosciuto');
  }
}
