import { FactCheckTwoTone } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useParams } from 'react-router';

import { useMutations } from '../../../../../api/hooks/useMutations';
import { useFileContext } from '../../../../../pages/fascicolo/file/list/context';
import type { FascicoloFile } from '../../../../../types/fascicolo-file';
import useToggleState from '../../../../hooks/useToggleState';
import withConfirmationButton from '../../../with-confirmation';

import { ElaborateDialog } from './elaborate-dialog';

type Props = {
  file: FascicoloFile;
};

export function Elaborate(props: Props) {
  const ButtonWithConfirmation = withConfirmationButton(IconButton);
  const [open, toggleOpen] = useToggleState(false);
  const { fascicolo } = useParams();
  const queryClient = useQueryClient();
  const { forceRefresh } = useFileContext();

  const handleSuccess = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [`fascicolo/${fascicolo}/file`],
      type: 'all',
      refetchType: 'active',
    });
    forceRefresh?.();
  }, [fascicolo, forceRefresh, queryClient]);

  const { update } = useMutations<Partial<FascicoloFile>>({
    key: `fascicolo/${fascicolo}/file/${props.file.id}`,
    onSuccess: handleSuccess,
  });

  const handleUpdate = useCallback(() => {
    update({ data: {} });
  }, [update]);

  if (props.file.fascicolofilestato === 'Elaborato') {
    return (
      <>
        <IconButton onClick={toggleOpen}>
          <Tooltip placement="top" title="Elaborato" followCursor>
            <FactCheckTwoTone color="success" />
          </Tooltip>
        </IconButton>
        <ElaborateDialog file={props.file} open={open} onClose={toggleOpen} />
      </>
    );
  }

  if (props.file.fascicolofilestato === 'In Elaborazione') {
    return (
      <IconButton disabled>
        <CircularProgress color="inherit" size={20} sx={{ mx: 0.36 }} />
      </IconButton>
    );
  }

  return (
    <ButtonWithConfirmation
      buttonProps={{ color: 'success' }}
      message="Vuoi elaborare questo file?"
      onConfirm={handleUpdate}
    >
      <Tooltip placement="top" title="Elabora" followCursor>
        <FactCheckTwoTone color="disabled" />
      </Tooltip>
    </ButtonWithConfirmation>
  );
}
