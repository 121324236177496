import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

import { useResizableDrawerContext } from '../../../../common/contexts/resizable-drawer-context';
import useToggleState from '../../../../common/hooks/useToggleState';

type Props = {
  contenuto: string;
  operatore: string;
  creato: string;
};
export function Message(props: Props) {
  const [expand, toggleExpand] = useToggleState(false);
  const [height, setHeight] = useState(0);
  const rotate = expand ? 'rotate(180deg)' : 'rotate(0deg)';
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useResizableDrawerContext();

  useEffect(() => {
    setHeight(ref?.current?.clientHeight || 0);
  }, [width]);

  return (
    <Box display="flex" flexDirection="column" mb={2}>
      <Card style={{ border: '1px solid #e7e7e7', marginRight: '15px' }}>
        <Box display="flex" gap={1} p={2} style={{ background: '#efefef' }}>
          <Typography fontWeight="bold" mr={2} variant="h6">
            {props.operatore}
          </Typography>
          <Typography fontWeight="bold" mr={2} variant="h6">
            {dayjs(props.creato).format('DD/MM/YYYY HH:mm')}
          </Typography>
        </Box>
        <Divider />
        <Box mb={0} ml={2} mr={2} mt={2}>
          <Typography
            ref={ref}
            dangerouslySetInnerHTML={{
              __html: `${props.contenuto}`,
            }}
            maxHeight={expand ? '100%' : '111px'}
            style={{ whiteSpace: 'normal', wordBreak: 'break-word', marginBottom: '10px', transition: 'all 200ms ease-in-out' }}
            variant="body2"
          />
          { height >= 110 && (
            <Button style={{ width: '100%', background: 'linear-gradient(transparent,  #fff, #fff)', marginTop: '-30px', height: '40px' }} onClick={toggleExpand}>
              <KeyboardArrowUpIcon style={{ transform: rotate, transition: 'all 0.2s linear', marginTop: '20px' }} />
            </Button>
          )}
        </Box>
      </Card>
    </Box>
  );
}
