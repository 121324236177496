import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useParams } from 'react-router';

import { useMutations } from '../../../../../../../api/hooks/useMutations';
import { useFileContext } from '../../../../../../../pages/fascicolo/file/list/context';
import type { CorrezioneDocumento, FascicoloFile } from '../../../../../../../types/fascicolo-file';
import InlineForm from '../../../../../inline-form';

import { CorrezioneFormInputs } from './form-inputs';

export function CorrezioneContent(props: {file: FascicoloFile, onClose: () => void;}) {
  const { fascicolo } = useParams();
  const { forceRefresh } = useFileContext();

  const queryClient = useQueryClient();

  const handleSuccess = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [`fascicolo/${fascicolo}/file`],
      type: 'all',
      refetchType: 'active',
    });
    forceRefresh?.();
    props.onClose();
  }, [fascicolo, forceRefresh, props, queryClient]);

  const { update } = useMutations({
    key: `fascicolo/${fascicolo}/file/${props.file.id}/convalidadoc`,
    onSuccess: handleSuccess,
  });

  const handleCreate = useCallback(
    (values: any) => {
      update({
        data: values,
      });
    },
    [update],
  );

  const correzioneDocumento: CorrezioneDocumento = {
    id: props.file.id,
    tipologiadocumento: props.file.tipologiadocumento,
    tipologiacorretta: null,
    tipologiadocumentorilevata: null,
    tipologiacustom: null,
    fascicoloFileElaborazione_id: props.file.fascicoloFileElaborazione_id || null,
  };

  return (
    <InlineForm
      defaultValues={{ ...correzioneDocumento }}
      handleSubmit={handleCreate}
      source=""
    >
      <CorrezioneFormInputs />
    </InlineForm>
  );
}
