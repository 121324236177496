import * as Sentry from '@sentry/react';
import type { AxiosError } from 'axios';
import axios from 'axios';

import {
  MockDataMapper,
  PaginationRequestMapper,
  requestSuccessInterceptor,
} from './interceptors';

export const MOCK = false;

export const API_BASE_PATH = MOCK
  ? 'http://localhost:8000/'
  : process.env.REACT_APP_API_URL;

export const instance = axios.create({
  baseURL: API_BASE_PATH,
});

instance.interceptors.response.use(MOCK ? MockDataMapper : r => r);
instance.interceptors.request.use(MOCK ? r => r : PaginationRequestMapper);

// Set request interceptor to add Auth token
instance.interceptors.request.use(requestSuccessInterceptor);

instance.interceptors.response.use(
  (result) => ensureRisultati(result),
  (error: AxiosError) => {
    setTimeout(() => {
      document.body.classList.remove('loading-indicator');
    }, 300);
    Sentry.captureException(error);

    return Promise.reject(error);
  },
);

function ensureRisultati(value: any) {
  setTimeout(() => {
    document.body.classList.remove('loading-indicator');
  }, 200);
  if (Array.isArray(value.data) && !value.data?.risultati) {
    return { ...value, data: { risultati: value.data } };
  }

  return value;
}
