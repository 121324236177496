import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';

import type { QuickAction } from '../../components/quick-actions';
import { QuickActionsMap } from '../../components/quick-actions';
import { useResizableDrawerContext } from '../resizable-drawer-context';

type Context = {
  handleSelectAction: (action: QuickAction) => void;
  handleCloseDrawer: () => void;
};

export const QuickActionsContext = createContext<Context>({} as Context);

export function QuickActionsContextProvider(props: { children: ReactNode }) {
  const { setComponent, closeDrawer, openDrawer } = useResizableDrawerContext();

  const handleCloseDrawer = useCallback(() => {
    setComponent(null);
    closeDrawer();
  }, [closeDrawer, setComponent]);

  const handleSelectAction = useCallback(
    (action: QuickAction) => {
      const Component = QuickActionsMap[action].component;
      setComponent(<Component />);
      openDrawer();
    },
    [openDrawer, setComponent],
  );

  const contextValue = useMemo(
    () => ({
      handleSelectAction,
      handleCloseDrawer,
    }),
    [handleCloseDrawer, handleSelectAction],
  );

  return (
    <QuickActionsContext.Provider value={contextValue}>
      {props.children}
    </QuickActionsContext.Provider>
  );
}

export function useQuickActionsDrawerContext() {
  return useContext(QuickActionsContext);
}
