import { useState, useCallback, useEffect } from 'react';

import { instance } from '../../../../../api/axios';
import { useNotification } from '../../../../contexts/notification-context';

export function useLocaleUrl(fileUrl: string) {
  const [url, setUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { notify } = useNotification();

  const getLocaleUrl = useCallback(async () => {
    setError(null);

    if (fileUrl.startsWith('blob:')) {
      setUrl(fileUrl);

      return;
    }

    try {
      const res = await instance.get(fileUrl, {
        responseType: 'blob',
      });

      if (!res.statusText || res.status >= 400) {
        throw new Error(
          `Errore nel caricamento del file: ${res.status} ${res.statusText}`,
        );
      }

      setUrl(window.URL.createObjectURL(new Blob([res.data])));
    } catch (err) {
      notify((err as Error)?.message, 'error');

      setUrl(null);
      setError((err as Error)?.message);
    }
  }, [fileUrl, notify]);

  useEffect(() => {
    getLocaleUrl();
  }, [getLocaleUrl]);

  return { url, error };
}
