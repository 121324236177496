import { MenuOpen } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';

import version from '../../../../version';
import { useAppDrawerContext } from '../../../contexts/app-drawer-context';
import { Logo } from '../../logo';

import { DrawerHeader } from './header';
import { SideMenu } from './side-menu';
import { StyledDrawer } from './styles';

export const drawerWidth: number = 290;
export const drawerCollapsedWidth = 80;

export function Drawer() {
  const { open, handleToggleDrawer } = useAppDrawerContext();
  const theme = useTheme();

  return (
    <StyledDrawer open={open} variant="permanent">
      <DrawerHeader>
        <Logo />
        {open && (
          <IconButton color="inherit" onClick={handleToggleDrawer}>
            <MenuOpen />
          </IconButton>
        )}
      </DrawerHeader>
      <Divider color={grey[800]} variant="middle" />
      <SideMenu />
      <Box sx={{ mb: 4 }} />
      <Typography
        align="center"
        sx={{
          color: grey[800],
          position: 'fixed',
          width: open ? drawerWidth : drawerCollapsedWidth,
          bottom: 0,
          p: 1,
          backgroundColor: theme.palette.primary.dark,
        }}
        variant="caption"
      >
        v{version}
      </Typography>
    </StyledDrawer>
  );
}
