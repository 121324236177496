import { instance } from '../axios';

export async function putMultiform<R>({
  id,
  path,
  data,
}: {
  id?: string | number;
  path: string;
  data: R;
}) {
  return instance.put(`${[path, id].filter(Boolean).join('/')}/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
