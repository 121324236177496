import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

import type {
  SottoMenu,
  SubMenuItem,
} from '../../../configurations/menu/types';
import { useMenus } from '../../../configurations/menu/useMenus';

type Context = {
  menu: SubMenuItem['menu'] | null;
  setActiveSubMenu: Dispatch<SetStateAction<SottoMenu | null>>;
};

export const SubMenuContext = createContext<Context>({} as Context);

export function SubMenuContextProvider(props: { children: ReactNode }) {
  const { data } = useMenus();
  const subMenus = useMemo(() => data?.sottomenu ?? [], [data]);

  const [activeSubMenu, setActiveSubMenu] = useState<SottoMenu | null>(null);

  const menu = useMemo(() => {
    if (activeSubMenu) {
      return (
        subMenus.find(subMenu => subMenu.id === activeSubMenu)?.menu || null
      );
    }

    return null;
  }, [activeSubMenu, subMenus]);

  const contextValue = useMemo(
    () => ({
      menu,
      setActiveSubMenu,
    }),
    [menu],
  );

  return (
    <SubMenuContext.Provider value={contextValue}>
      {props.children}
    </SubMenuContext.Provider>
  );
}

export function useSubMenuContext() {
  return useContext(SubMenuContext);
}
