import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useNotification } from '../../common/contexts/notification-context';
import type { Endpoints } from '../../common/hooks/use-action';
import { useAction } from '../../common/hooks/use-action';
import { postJson } from '../post/postJson';
import { putJson } from '../put/putJson';
import { putMultiform } from '../put/putMultiform';

type UseMutationsParams = {
  id?: string | number | null;
  unique?: boolean;
  key: string;
  onSuccess?: (response: unknown) => void;
  redirect?: Endpoints;
  avoidInvalidate?: boolean;
  keepAlive?: boolean;
  successMessage?: string;
};

export type MutationError = Error & {
  response: { data: { messaggio_errore: string; campo_errore: string }[] };
};

export function useMutations<R>(params: UseMutationsParams) {
  const queryClient = useQueryClient();
  const { notify } = useNotification();
  const navigate = useNavigate();
  const { isUpdate } = useAction();

  const createMutation = useMutation<
    AxiosResponse<{ id: string }, MutationError>,
    MutationError,
    { path: string; data: R }
  >([params.key], postJson);

  const updateMutation = useMutation<
    AxiosResponse<{ id?: string }, MutationError>,
    MutationError,
    { id?: string | number; path: string; data: R }
  >([params.key], putJson);

  const updateMultiformMutation = useMutation<
    AxiosResponse<{ id?: string }, MutationError>,
    MutationError,
    { id?: string | number; path: string; data: R }
  >([params.key], putMultiform);

  const reset = useCallback(() => {
    createMutation.reset();
    updateMutation.reset();
  }, [createMutation, updateMutation]);

  const create = useCallback(
    ({ data }: { data: R }) => {
      reset();
      createMutation.mutate({ path: params.key, data });
    },
    [createMutation, params.key, reset],
  );

  const update = useCallback(
    ({ id, data }: { id?: string | number; data: R }) => {
      reset();
      updateMutation.mutate({ id, path: params.key, data });
    },
    [reset, updateMutation, params.key],
  );

  const updateMultiform = useCallback(
    ({ id, data }: { id?: string | number; data: R }) => {
      reset();
      updateMultiformMutation.mutate({ id, path: params.key, data });
    },
    [reset, updateMultiformMutation, params.key],
  );

  const mutate = useCallback(
    ({ data }: { data: R }) => {
      if (params.id) {
        update({ id: params.id, data });
      } else if (params.unique && isUpdate) {
        update({ data });
      } else {
        create({ data });
      }
    },
    [params.id, params.unique, isUpdate, update, create],
  );

  const isError =
    createMutation.isError ||
    updateMutation.isError ||
    updateMultiformMutation.isError;

  const isLoading =
    createMutation.isLoading ||
    updateMutation.isLoading ||
    updateMultiformMutation.isLoading;

  const isSuccess =
    createMutation.isSuccess ||
    updateMutation.isSuccess ||
    updateMultiformMutation.isSuccess;

  const error =
    createMutation.error ||
    updateMutation.error ||
    updateMultiformMutation.error;

  const data =
    createMutation.data || updateMutation.data || updateMultiformMutation.data;

  useEffect(() => {
    const id = params.id ?? data?.data?.id;

    if (isSuccess) {
      if (params.onSuccess) {
        params.onSuccess(data?.data);
      }

      if (!params.keepAlive) {
        reset();
      }

      if (!params.avoidInvalidate) {
        queryClient.invalidateQueries({
          queryKey: [params.key],
          refetchType: 'active',
          type: 'all',
        });
        queryClient.resetQueries([params.key]);
      }

      if (params.redirect === 'show' && id) {
        navigate(`/${params.key}/${id}`);
      }

      if (params.redirect === 'update' && id) {
        navigate(`/${params.key}/${id}/update`);
      }

      if (params.redirect === 'show' && params.unique) {
        navigate('../show');
      }

      if (params.redirect === 'list') {
        navigate(`/${params.key}`);
      }

      notify(
        params.successMessage ?? 'Salvataggio effettuato con successo',
        'success',
      );
    }
  }, [data?.data, isSuccess, navigate, notify, params, queryClient, reset]);

  useEffect(() => {
    if (isError) {
      notify(
        error?.message ??
          'Ooops.. Qualcosa è andato storto, riprova più tardi!',
        'error',
      );
    }
  }, [isError, error, notify]);

  return {
    isError,
    isLoading,
    isSuccess,
    reset,
    error,
    data,
    create,
    update,
    updateMultiform,
    mutate,
  };
}
