import { Skeleton } from '@mui/material';

import { Grid } from '../grid';
import { PageLayout } from '../page-layout';

export function LoadingPage() {
  return (
    <PageLayout
      actions={<Skeleton height={36} variant="rounded" width={112} />}
      subtitle={<Skeleton variant="text" width={400} />}
      title={<Skeleton height={30} variant="rounded" width={250} />}
    >
      <Grid p={0}>
        <Skeleton variant="rounded" />
        <Skeleton variant="rounded" />
        <Skeleton variant="rounded" />
        <Skeleton variant="rounded" />
        <Skeleton variant="rounded" />
        <Skeleton variant="rounded" />
      </Grid>
    </PageLayout>
  );
}
