import { Box, Divider, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Domains } from '../../../../../../../../configurations/domains/types';
import type { CorrezioneDocumento } from '../../../../../../../../types/fascicolo-file';
import { StaticAutoComplete } from '../../../../../../form/fields/static-autocomplete';
import { TextField } from '../../../../../../form/fields/text-field';
import { Grid } from '../../../../../../grid';

export function CorrezioneFormInputs() {
  const { setValue } = useFormContext<CorrezioneDocumento>();
  const { tipologiadocumento, tipologiacorretta, tipologiadocumentorilevata } = useWatch<CorrezioneDocumento>();

  useEffect(() => {
    if (tipologiacorretta !== 2) {
      setValue('tipologiadocumentorilevata', null);
      setValue('tipologiacustom', '');
    }
    if (tipologiadocumentorilevata !== 1000) {
      setValue('tipologiacustom', '');
    }
  }, [setValue, tipologiacorretta, tipologiadocumentorilevata]);

  return (
    <>
      <Box sx={{ maxHeight: '500px' }}>
        <Grid lg={1} md={1} pt={0} sm={1}>
          <Typography sx={{ paddingTop: '5px' }}>Tipologia documento rilevata: <b>{titleCase(tipologiadocumento)}</b></Typography>
          <StaticAutoComplete
            domain={Domains.ELABORAZIONE_FEEDBACK_STATO}
            inputLabel="Esito rilevazione"
            name="tipologiacorretta"
            required
          />
        </Grid>
        <Grid lg={2} md={2} pt={0} sm={1}>
          <StaticAutoComplete
            AutocompleteProps={{ disabled: ![2].includes(Number(tipologiacorretta)) }}
            domain={Domains.CATEGORIZZAZIONE_TIPIZZAZIONE}
            inputLabel="Tipologia corretta"
            name="tipologiadocumentorilevata"
          />
          <TextField
            disabled={![1000].includes(Number(tipologiadocumentorilevata))}
            label="Altro"
            name="tipologiacustom"
          />
        </Grid>
        <Grid lg={1} md={1} pt={0} sm={1}>
          <TextField
            label="Note"
            name="note"
          />
        </Grid>
      </Box>
      <Divider />
    </>
  );
}

function titleCase(string: string | undefined) {
  if (string === undefined || string === null || string.length <= 2) return string;

  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}
