import { CheckTwoTone, OpenInNewTwoTone } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { FileElaborato } from '../../../../../../../types/fascicolo-file';
import withConfirmationButton from '../../../../../with-confirmation';

const ButtonWithConfirmation = withConfirmationButton(Button);

export function ProcessAvvisiVendita(props: { file: FileElaborato; onClose: () => void }) {
  const { fascicolo } = useParams();
  const navigate = useNavigate();

  const handleGoToCreateAzioneEsecutiva = useCallback(() => {
    const path = `/fascicolo/${fascicolo}/azioneesecutiva/add`;

    navigate(path, { state: props.file });
    props.onClose();
  }, [fascicolo, navigate, props]);

  const handleGoToLinkedAzioneEsecutiva = useCallback(() => {
    const path = `/fascicolo/${fascicolo}/azioneesecutiva/${props.file?.link_azioneesecutiva}`;

    navigate(path);
    props.onClose();
  }, [fascicolo, props, navigate]);

  if (props.file.link_azioneesecutiva) {
    return (
      <Button
        color="success"
        startIcon={<OpenInNewTwoTone />}
        variant="contained"
        onClick={handleGoToLinkedAzioneEsecutiva}
      >
        Vai all'esecuzione immobiliare collegata
      </Button>
    );
  }

  return (
    <ButtonWithConfirmation
      buttonProps={{
        color: 'success',
        variant: 'contained',
        startIcon: <CheckTwoTone />,
      }}
      message="Vuoi procedere alla creazione dell'esecuzione immobiliare?"
      onConfirm={handleGoToCreateAzioneEsecutiva}
    >
      Processa
    </ButtonWithConfirmation>
  );
}
