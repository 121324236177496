import { MenuRounded } from '@mui/icons-material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { useAppDrawerContext } from '../../../../contexts/app-drawer-context';
import { useMenuContext } from '../../../../contexts/menu-context';

export function DrawerHandle() {
  const { open, handleToggleDrawer } = useAppDrawerContext();
  const { activeSection } = useMenuContext();

  return (
    <Box alignItems="center" display={open ? 'none' : 'flex'}>
      {!!activeSection?.children?.length && (
        <IconButton color="inherit" sx={{ mr: 2 }} onClick={handleToggleDrawer}>
          <MenuRounded color="primary" />
        </IconButton>
      )}
    </Box>
  );
}
