import type { TooltipProps } from '@mui/material';
import {
  Box,
  Button,
  ClickAwayListener,
  Tooltip,
  Typography,
} from '@mui/material';
import type { ReactNode } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

interface ConfirmationButtonProps {
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
  onOpen?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  buttonProps: any;
  tooltipProps?: TooltipProps;
  children: ReactNode;
  isLoading?: boolean;
}

function withConfirmationButton<T extends object>(
  WrappedButton: React.ComponentType<T>,
) {
  return function ConfirmationButton({
    message,
    onConfirm,
    onCancel,
    confirmLabel,
    cancelLabel,
    buttonProps,
    tooltipProps,
    children,
    onOpen,
    isLoading,
  }: ConfirmationButtonProps & T) {
    const [open, setOpen] = useState(false);

    const handleConfirm = useCallback(() => {
      setOpen(false);
      onConfirm();
    }, [onConfirm]);

    const handleCancel = useCallback(() => {
      setOpen(false);
      onCancel?.();
    }, [onCancel]);
    const handleOpen = useCallback(() => {
      setOpen(true);
      onOpen?.();
    }, [onOpen]);
    const renderTooltip = useMemo(
      () => (
        <>
          <Typography>{message}</Typography>
          <Box display="flex" gap={1} sx={{ mt: 2, mb: 1 }}>
            <Button
              disabled={isLoading}
              size="small"
              variant="contained"
              onClick={handleConfirm}
            >
              {confirmLabel ?? 'Conferma'}
            </Button>
            <Button
              color="secondary"
              disabled={isLoading}
              size="small"
              variant="contained"
              onClick={handleCancel}
            >
              {cancelLabel ?? 'Annulla'}
            </Button>
          </Box>
        </>
      ),
      [
        message,
        isLoading,
        handleConfirm,
        confirmLabel,
        handleCancel,
        cancelLabel,
      ],
    );

    return (
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Tooltip
          open={open}
          placement="top"
          title={renderTooltip}
          arrow
          {...tooltipProps}
        >
          <WrappedButton onClick={handleOpen} {...buttonProps}>
            {children}
          </WrappedButton>
        </Tooltip>
      </ClickAwayListener>
    );
  };
}

export default withConfirmationButton;
