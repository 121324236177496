import * as Sentry from '@sentry/react';
import { httpContextIntegration } from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    httpContextIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.sessionTimingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    // DA TESTARE
    // Sentry.captureConsoleIntegration(),
    // Sentry.extraErrorDataIntegration(),
    // Sentry.reportingObserverIntegration(),
    // Sentry.reactRouterV6BrowserTracingIntegration({
    //   useEffect,
    //   useLocation,
    //   useNavigationType,
    //   createRoutesFromChildren,
    //   matchRoutes,
    // }),
    // Sentry.breadcrumbsIntegration({
    //   console: true,
    //   dom: true,
    //   fetch: true,
    //   history: true,
    //   xhr: true,
    // }),
  ],
  // @ts-ignore
  failedRequestStatusCodes: [400, 401, 403, 404, 429, 500, 503] as any,
  sendDefaultPii: true,
  environment: process.env.NODE_ENV,
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/apistage\.ceore\.it\//,
    /^https:\/\/apilyra\.fire\.eu\//,
    /^https:\/\/lyra\.fire\.eu\//,
    /^https:\/\/httpstat\.us\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
