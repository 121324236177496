import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import MuiTabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';

export const Tabs = styled(MuiTabs)`
  align-items: flex-end;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => alpha(theme.palette.primary.dark, 0.8)};
`;

export const TabWrapper = styled(Box)`
  .MuiTabs-flexContainer {
    position: relative;
    z-index: 1;
  }
  .MuiTab-root {
    text-transform: none;
    font-weight: 700;
    color: ${({ theme }) => alpha(theme.palette.primary.dark, 0.8)};
    border-radius: 8px 8px 0 0;
    background: ${({ theme }) => theme.palette.background.light};
    margin-right: 5px;
    opacity: 1;
    padding: 6px 16px;
    min-height: 36px;
    transition-delay: 0.2s;
    transition-duration: 0.1s;

    &.Mui-selected {
      color: ${({ theme }) => theme.palette.text.contrast};
      background: ${({ theme }) => alpha(theme.palette.primary.dark, 0.8)};
    }
  }
`;

export function tabIndicatorStyle(theme: Theme) {
  return {
    borderRadius: '8px 8px 0 0',
    boxShadow: `0 0 0 1px inset ${alpha(theme.palette.primary.dark, 0.5)}`,
    position: 'relative',
    height: 36,
    top: 0,
  };
}

export function tabScrollButtonStyle(theme: Theme) {
  return {
    height: 36,
    opacity: 1,
    width: 20,
    margin: '0 4px',
    background: theme.palette.text.contrast,
    borderRadius: '8px 8px 0 0',
  };
}
