import UpdateIcon from '@mui/icons-material/Update';
import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useMutations } from '../../../../../../../api/hooks/useMutations';
import { useFileContext } from '../../../../../../../pages/fascicolo/file/list/context';
import withConfirmationButton from '../../../../../with-confirmation';

const ButtonWithConfirmation = withConfirmationButton(Button);

export function Rielabora(props: { fileId: number; fascicoloFileElaborazione_id: number | null; onClose: () => void }) {
  const { fascicolo } = useParams();
  const { forceRefresh } = useFileContext();

  const queryClient = useQueryClient();

  const handleSuccess = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [`fascicolo/${fascicolo}/file`],
      type: 'all',
      refetchType: 'active',
    });
    forceRefresh?.();
    props.onClose();
  }, [fascicolo, forceRefresh, props, queryClient]);

  const { update } = useMutations({
    key: `fascicolo/${fascicolo}/file/${props.fileId}/rielabora`,
    onSuccess: handleSuccess,
  });

  const handleCreate = useCallback(
    () => {
      update({
        data: {
          fascicoloFileElaborazione_id: props.fascicoloFileElaborazione_id,
        },
      });
    },
    [props.fascicoloFileElaborazione_id, update],
  );

  return (
    <ButtonWithConfirmation
      buttonProps={{
        color: 'warning',
        variant: 'contained',
        startIcon: <UpdateIcon />,
      }}
      message="Vuoi procedere con la rielaborazione?"
      onConfirm={handleCreate}
    >
      Rielabora
    </ButtonWithConfirmation>
  );
}
