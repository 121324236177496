export function PreviewCardCSS() {
  return {
    position: 'absolute',
    left: 25,
    right: 25,
    top: 25,
    bottom: 125,
    zIndex: 2,
  };
}
