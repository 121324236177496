import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

import type { Route } from '../../../configurations/routes/types';

type Context = {
  route: Route & { parentRoute: Route | null };
  setRoute: (route: Route & { parentRoute: Route | null }) => void;
};

export const RouterContext = createContext<Context>({} as Context);

export function RouterContextProvider(props: { children: ReactNode }) {
  const [route, setRoute] = useState<Route & { parentRoute: Route | null }>(
    {} as Route & { parentRoute: Route | null },
  );

  const contextValue = useMemo(() => ({ route, setRoute }), [route]);

  return (
    <RouterContext.Provider value={contextValue}>
      {props.children}
    </RouterContext.Provider>
  );
}

export function useRouterContext() {
  return useContext(RouterContext);
}
