import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';

import { useAppDrawerContext } from '../../../contexts/app-drawer-context';
import { useResizableDrawerContext } from '../../../contexts/resizable-drawer-context';
import { TabsNavigation } from '../../tabs-navigation';
import { drawerWidth, drawerCollapsedWidth } from '../drawer';
import { DrawerHeader } from '../drawer/header';

type MainWrapperProps = {
  open: boolean;
  openResizableDrawer: boolean;
  resizableDrawerWidth: number;
};
export const MainWrapper = styled('main')<MainWrapperProps>(
  ({ theme, open, openResizableDrawer, resizableDrawerWidth }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    ...getTransitionStyles(
      theme,
      open,
      openResizableDrawer,
      resizableDrawerWidth,
    ),
  }),
);

export function Main(props: { children: ReactNode }) {
  const { children } = props;
  const { open } = useAppDrawerContext();
  const { open: openResizableDrawer, width: resizableDrawerWidth } =
    useResizableDrawerContext();

  return (
    <MainWrapper
      open={open}
      openResizableDrawer={openResizableDrawer}
      resizableDrawerWidth={resizableDrawerWidth}
    >
      <DrawerHeader />

      <Box p={3}>
        <TabsNavigation />
        {children}
      </Box>
    </MainWrapper>
  );
}

function getTransitionStyles(
  theme: Theme,
  open: boolean,
  openResizableDrawer: boolean,
  resizableDrawerWidth: number,
) {
  return {
    maxWidth: calculateWidth(open, openResizableDrawer, resizableDrawerWidth),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing[open ? 'easeOut' : 'sharp'],
      duration:
        theme.transitions.duration[open ? 'enteringScreen' : 'leavingScreen'],
    }),
  };
}

function calculateWidth(
  open: boolean,
  openResizableDrawer: boolean,
  resizableDrawerWidth: number,
) {
  if (open && openResizableDrawer) {
    return `calc(100vw - ${drawerWidth + resizableDrawerWidth}px)`;
  }
  if (open) {
    return `calc(100vw - ${drawerWidth}px)`;
  }
  if (openResizableDrawer) {
    return `calc(100vw - ${resizableDrawerWidth + drawerCollapsedWidth}px)`;
  }

  return `calc(100vw - ${drawerCollapsedWidth}px)`;
}
