import { instance } from '../axios';

export async function deleteJson({
  id,
  path,
}: {
  id: string | number;
  path: string;
}) {
  return instance.delete(`${[path, id].filter(Boolean).join('/')}/`);
}
