import { NotificationsActiveTwoTone } from '@mui/icons-material';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import { Badge, IconButton, ListItemIcon } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import type { MouseEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { useGetMany } from '../../../../../api/hooks/useGetMany';
import type { Notifica, NotificaFilter } from '../../../../../types/notifica';

export function NotificationMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const { data: newNotifications, isLoading: isLoadingNewNotifications } =
    useGetMany<NotificaFilter, Notifica>(
      'notifica',
      { letta: 0 },
      { refetchInterval: 30000 },
    );
  const { data: notifications, isLoading: isLoadingNotifications } = useGetMany<
    NotificaFilter,
    Notifica
  >('notifica', { letta: 1 }, { refetchInterval: 30000 });

  const allNotifications = useMemo(
    () => newNotifications?.concat(notifications ?? []).slice(0, 4),
    [newNotifications, notifications],
  );

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleNotifica = useCallback(
    (id: number) => {
      navigate(`/notifica/${id}/show`);
      setAnchorEl(null);
    },
    [navigate],
  );
  const handleNotificaList = useCallback(() => {
    navigate('/notifica');
    setAnchorEl(null);
  }, [navigate]);

  return (
    <Box alignItems="center" display="flex" ml={2}>
      <Badge badgeContent={newNotifications?.length} color="secondary">
        <IconButton
          color="primary"
          disabled={isLoadingNewNotifications || isLoadingNotifications}
          size="small"
          sx={{ minWidth: 0 }}
          onClick={handleClick}
        >
          <NotificationsActiveTwoTone />
        </IconButton>
      </Badge>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {allNotifications?.map(notifica => (
          <Box key={notifica?.id}>
            <MenuItem
              sx={{ overflow: 'hidden', maxHeight: '80px', width: '320px' }}
              onClick={() => handleNotifica(notifica.id)}
            >
              <ListItemIcon>
                {!notifica?.letta && (
                  <Box
                    height={10}
                    sx={{ borderRadius: '100%', bgcolor: 'primary.main' }}
                    width={10}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={notifica?.oggettointerna}
                secondary={dayjs(notifica?.data_notifica).format('DD/MM/YYYY')}
              />
            </MenuItem>
            <Divider />
          </Box>
        ))}

        <MenuItem onClick={handleNotificaList}>
          <ListItemText>Vai alle notifiche</ListItemText>
          <ListItemIcon>
            <LaunchTwoToneIcon color="primary" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
      <Divider orientation="vertical" sx={{ ml: 2 }} flexItem />
    </Box>
  );
}
