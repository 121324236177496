import { CloseTwoTone } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
  Box,
} from '@mui/material';

import {
  type FascicoloFile,
} from '../../../../../../types/fascicolo-file';

import { CorrezioneContent } from './correzione-content';

type Props = {
  file: FascicoloFile;
  onClose: () => void;
  open: boolean;
};
export function ModifyDialog(props: Props) {
  return (
    <Dialog maxWidth={false} open={props.open} onClose={props.onClose}>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <DialogTitle
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          variant="h4"
        >
          Convalida analisi documentale
        </DialogTitle>
        <IconButton style={{ marginRight: '10px' }} onClick={props.onClose}>
          <CloseTwoTone />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        <Box width="60vw">
          <CorrezioneContent file={props.file} onClose={props.onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
