import {
  DeleteTwoTone,
  EditTwoTone,
  VisibilityTwoTone,
} from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import type { ICellRendererParams } from 'ag-grid-community';
import type { ReactElement } from 'react';
import { useCallback, useMemo } from 'react';
import { matchRoutes, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { useRoutes } from '../../../configurations/routes/useRoutes';
import { useAuthContext } from '../../contexts/auth-context';
import withConfirmation from '../with-confirmation';

const ButtonWithConfirmation = withConfirmation(IconButton);

export type RowActionsProps = {
  disabled?: boolean;
  params: ICellRendererParams;
  view?: boolean;
  update?: boolean;
  delete?: boolean;
  forceDelete?: boolean;
  onDelete?: (params: ICellRendererParams) => void;
  onUpdate?: (params: ICellRendererParams) => void;
  customActions?: (params: ICellRendererParams) => ReactElement[];
  customResourcePath?: (params: ICellRendererParams) => string;
};

export function RowActions(props: RowActionsProps) {
  const { isAdmin } = useAuthContext();
  const { pathname } = useLocation();
  const resourcePath = props.customResourcePath?.(props.params) ?? pathname;

  const routes = useRoutes();
  const matchUpdate = useMemo(
    () => matchRoutes(routes?.data ?? [], `${resourcePath}/:id/update`),
    [resourcePath, routes?.data],
  );
  const canUpdate = !!matchUpdate?.length && !props.params?.data?.readonly;

  const matchView = useMemo(
    () => matchRoutes(routes?.data ?? [], `${resourcePath}/:id/show`),
    [resourcePath, routes?.data],
  );
  const canView = !!matchView?.length;
  // Diego
  const canDeleteOperator = props.forceDelete ? props.forceDelete : (canUpdate && props.delete);
  const canDelete = isAdmin ? (props.delete && !props.params?.data?.readonly) : canDeleteOperator;
  // Diego END

  const navigate = useNavigate();

  const handleEdit = useCallback(() => {
    if (props.onUpdate) {
      props.onUpdate(props.params);

      return;
    }
    navigate(`${props.params.data?.id}/update`, {
      state: props.params.data,
    });
  }, [navigate, props]);

  const handleView = useCallback(() => {
    navigate(`${props.params.data?.id}/show`, {
      state: props.params.data,
    });
  }, [navigate, props.params.data]);

  const handleDelete = useCallback(() => {
    props.onDelete?.(props.params);
  }, [props]);

  return (
    <Box display="flex" gap={1} justifyContent="flex-end" width="100%">
      {props.customActions?.(props.params)}
      {props.view && canView && (
        <IconButton
          color="success"
          disabled={props.disabled}
          onClick={handleView}
        >
          <Tooltip placement="top" title="Visualizza" followCursor>
            <VisibilityTwoTone />
          </Tooltip>
        </IconButton>
      )}
      {props.update && canUpdate && (
      <IconButton
        color="primary"
        disabled={props.disabled}
        onClick={handleEdit}
      >
        <Tooltip placement="top" title="Modifica" followCursor>
          <EditTwoTone />
        </Tooltip>
      </IconButton>
      )}
      { canDelete && (
      <ButtonWithConfirmation
        buttonProps={{ color: 'warning', disabled: props.disabled }}
        message="Sei sicuro di volere confermare l'eliminazione?"
        onConfirm={handleDelete}
      >
        <Tooltip placement="top" title="Cancella" followCursor>
          <DeleteTwoTone />
        </Tooltip>
      </ButtonWithConfirmation>
      )}
    </Box>
  );
}
