import { DownloadTwoTone } from '@mui/icons-material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import PrintIcon from '@mui/icons-material/Print';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import print from 'print-js';
import { useCallback, useEffect, useRef, useState } from 'react';
import FileViewer from 'react-file-viewer';

import { useResizableDrawerContext } from '../../../../../../contexts/resizable-drawer-context';
import { useLocaleUrl } from '../../../../file-hooks/use-locale-url';
import { PreviewError } from '../../error';

export function PreviewContent(props: {
  fileLink: string;
  height: string | number;
  fileType?: string;
  canDownload?: boolean;
}) {
  const { width } = useResizableDrawerContext();
  const fileType = props.fileLink.split('.').pop();
  const [zoomImg, setZoomImg] = useState(1);
  const [deltaY, setDeltaY] = useState(0);
  const [zoomView, setZoomView] = useState('100%');
  const pdfobject = document.getElementById('pg-viewer');
  const ref = useRef<HTMLDivElement | null>(null);

  const filePath = props.fileLink;

  const { url, error } = useLocaleUrl(filePath);

  const handleDownload = useCallback(() => {
    const iframe = document.createElement('iframe');
    if (url && iframe) {
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${dayjs().format('YYYY-MM-DD')}.pdf`;

      downloadLink.click();
      URL.revokeObjectURL(url);
    }
  }, [url]);

  const handleZoom = useCallback((value: number) => {
    const zoomTmp = zoomImg + value;
    if (zoomTmp >= 0.1 && zoomTmp <= 3.1) {
      setZoomImg(zoomTmp);
      if (pdfobject)pdfobject.style.setProperty('zoom', zoomTmp.toString());
    }
  }, [pdfobject, zoomImg]);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      // Windows & Linux: ctrl, Mac: meta
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        const tmpDeltaY = deltaY + e.deltaY;
        setDeltaY(tmpDeltaY);
        if (deltaY < tmpDeltaY)handleZoom(-0.1);
        if (deltaY > tmpDeltaY)handleZoom(0.1);
      }
    };

    document.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, [deltaY, handleZoom]);

  const handleresetZoom = useCallback(() => {
    setZoomImg(1);
    setDeltaY(0);
    if (pdfobject)pdfobject.style.setProperty('zoom', '1');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfobject, zoomImg]);

  useEffect(() => {
    const zoomViewTmp = Math.round(zoomImg * 100);
    setZoomView(`${zoomViewTmp.toString()}%`);
  }, [zoomImg]);

  const extFile = props.fileType?.toLowerCase() || fileType?.toLowerCase();

  const handlePrint = () => {
    const types = extFile === 'pdf' ? 'pdf' : 'image';
    print({ printable: url, type: types });
  };

  return (
    <Box
      height={props.height}
      p={2}
      sx={{ maxHeight: props.height, overflowY: 'auto' }}
      textAlign="center"
    >
      <PreviewError error={error} />
      {url && (
        <>
          <Box style={{ position: 'absolute', zIndex: 100, background: '#2929291f', borderRadius: '10px' }}>
            <Tooltip placement="top" title="Zoom Out" followCursor>
              <IconButton onClick={() => handleZoom(-0.1)}>
                <ZoomOutIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Reset Zoom" followCursor>
              <Button
                color="inherit"
                sx={{ boxShadow: 'none', minWidth: '67.81px', color: '#5569ff' }}
                variant="contained"
                onClick={() => handleresetZoom()}
              >
                {zoomView}
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Zoom In" followCursor>
              <IconButton onClick={() => handleZoom(0.1)}>
                <ZoomInIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Scarica" followCursor>
              <IconButton onClick={handleDownload}>
                <DownloadForOfflineIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Stampa" followCursor>
              <IconButton onClick={handlePrint}>
                <PrintIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
          <FileViewer
            key={width}
            ref={ref}
            errorComponent={PreviewError}
            filePath={url}
            fileType={extFile}
            fullWidth
          />
        </>
      )}
      {props.canDownload && (
        <Button
          color="success"
          startIcon={<DownloadTwoTone />}
          sx={{ position: 'absolute', right: 15, bottom: 15 }}
          variant="contained"
          onClick={handleDownload}
        >
          Scarica documento
        </Button>
      )}
    </Box>
  );
}
