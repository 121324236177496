import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AddCircleTwoTone, CloseTwoTone } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import { array, object, string } from 'yup';

import { useMutations } from '../../../../api/hooks/useMutations';
import type { FascicoloFilePost } from '../../../../types/fascicolo-file';
import useToggleState from '../../../hooks/useToggleState';
import { Form } from '../../form';
import { Actions } from '../../inline-form/actions';

import { parseFascicoloFile } from './data-parser';
import { FileContent } from './file-content';

export const schema = object({
  filesDetails: array()
    .of(
      object().shape({
        descrizione: string().required('Campo obbligatorio'),
        filename: string().required('Campo obbligatorio'),
        fascicolofilecategoria: string().required('Campo obbligatorio'),
      }),
    )
    .min(1, 'Campo obbligatorio')
    .required(),
});

export function AddNewFile(props: { onSuccess?: () => void }) {
  const { fascicolo, ...rest } = useParams();
  const [open, toggle, , toggleOff] = useToggleState(false);

  const { create } = useMutations({
    key: `fascicolo/${fascicolo}/file`,
    onSuccess: props.onSuccess,
  });

  const [sorgente, sorgenteId] = Object.entries(rest ?? {})?.[0] ?? [];

  const handleSubmit = useCallback(
    (values: Partial<FascicoloFilePost>) => {
      create({
        data: parseFascicoloFile({
          ...values,
          sorgente: sorgente ?? null,
          sorgente_id: sorgenteId ? Number(sorgenteId) : null,
        }),
      });
      toggle();
    },
    [create, sorgente, sorgenteId, toggle],
  );

  return (
    <>
      <Box alignItems="center" display="flex" justifyContent="center">
        <Button
          color="success"
          startIcon={<AddCircleTwoTone />}
          sx={{ width: 200 }}
          variant="contained"
          onClick={toggle}
        >
          Aggiungi file
        </Button>
      </Box>
      <Dialog maxWidth={false} open={open} onClose={toggleOff}>
        <DialogTitle
          alignItems="center"
          component="div"
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h3">Aggiungi file</Typography>
          <IconButton onClick={toggleOff}>
            <CloseTwoTone />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Form
            options={{
              mode: 'onChange',
              resolver: yupResolver(schema),
            }}
            onSubmit={handleSubmit}
          >
            <FileContent />
            <Actions />
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
}
