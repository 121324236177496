import { CloseTwoTone } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import { PreviewFileNameCSS } from './styles';

export function PreviewHeader(props: {
  fileName: string;
  onClose: () => void;
}) {
  return (
    <Box alignItems="center" display="flex" justifyContent="space-between">
      <Typography sx={PreviewFileNameCSS} variant="h5">
        Visualizzazione del file: <i>{props.fileName}</i>
      </Typography>
      <IconButton onClick={props.onClose}>
        <CloseTwoTone />
      </IconButton>
    </Box>
  );
}
