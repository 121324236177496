import type { AlertColor } from '@mui/material';
import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Notification } from '../../components/notification';

type Context = {
  message?: string | null;
  dismiss: () => void;
  severity?: AlertColor;
  notify: (message: string, severity?: AlertColor) => void;
};

export const NotificationContext = createContext<Context>({} as Context);

export function NotificationContextProvider(props: { children: ReactNode }) {
  const [severity, setSeverity] = useState<AlertColor>('info');
  const [message, setMessage] = useState<string | null>();

  const notify = useCallback((newMessage: string, newSeverity?: AlertColor) => {
    setMessage(newMessage);
    setSeverity(newSeverity ?? 'info');
  }, []);

  const dismiss = useCallback(() => {
    setMessage(null);
    setSeverity('info');
  }, []);

  const contextValue = useMemo(
    () => ({
      message,
      dismiss,
      severity,
      notify,
    }),
    [message, dismiss, severity, notify],
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {props.children}
      <Notification />
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  return useContext(NotificationContext);
}
