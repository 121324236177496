import {
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  Backdrop,
  Card,
  Box,
} from '@mui/material';

import { PreviewContent } from './content';
import { PreviewHeader } from './header';
import { PreviewCardCSS } from './styles';

export type PreviewDialogProps = {
  link: string;
  onClose: () => void;
  open?: boolean;
  disablePortal?: boolean;
  fileType?: string;
  canDownload?: boolean;
};

export function PreviewDialog(props: PreviewDialogProps) {
  const fileName =
    props.link
      ?.split('/')
      .pop()
      ?.split('_')
      .filter((_, i) => i)
      ?.join('_') ?? props.link;
  const fileLink = props.link;

  if (props.disablePortal) {
    return (
      <Box>
        <Backdrop sx={{ position: 'absolute' }} open />
        <Card elevation={5} sx={PreviewCardCSS}>
          <Box p={2}>
            <PreviewHeader fileName={fileName} onClose={props.onClose} />
          </Box>
          <Divider />
          <PreviewContent
            canDownload={props.canDownload}
            fileLink={fileLink}
            fileType={props.fileType}
            height="calc(100% - 65px)"
          />
        </Card>
      </Box>
    );
  }

  return (
    <Dialog
      maxWidth={false}
      open={!!props.open}
      fullWidth
      onClose={props.onClose}
    >
      <DialogTitle component="div">
        <PreviewHeader fileName={fileName} onClose={props.onClose} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <PreviewContent
          canDownload={props.canDownload}
          fileLink={fileLink}
          fileType={props.fileType}
          height={700}
        />
      </DialogContent>
    </Dialog>
  );
}
