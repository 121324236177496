import { WarningTwoTone, CloseTwoTone } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type Context = {
  message?: string | null;
  dismiss: () => void;
  notify: (message: string) => void;
};

export const ConfirmPromptContext = createContext<Context>({} as Context);

export function ConfirmPromptContextProvider(props: { children: ReactNode }) {
  const [message, setMessage] = useState<string | null>();

  const notify = useCallback((newMessage: string) => {
    setMessage(newMessage);
  }, []);

  const dismiss = useCallback(() => {
    setMessage(null);
  }, []);

  const contextValue = useMemo(
    () => ({
      message,
      dismiss,
      notify,
    }),
    [message, dismiss, notify],
  );

  return (
    <ConfirmPromptContext.Provider value={contextValue}>
      {props.children}
      <Dialog open={!!message} onClose={dismiss}>
        <DialogTitle
          alignItems="center"
          component="div"
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h3">Conferma</Typography>
          <IconButton onClick={dismiss}>
            <CloseTwoTone />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Alert icon={<WarningTwoTone />} severity="warning">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">{message}</Typography>
            </Box>
          </Alert>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="contained" onClick={dismiss}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmPromptContext.Provider>
  );
}

export function useConfirmPrompt() {
  return useContext(ConfirmPromptContext);
}
