import { useState, useCallback } from 'react';

const useToggleState = (
  initialState: boolean,
): [boolean, () => void, () => void, () => void] => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState(curState => !curState), []);

  const toggleOn = useCallback(() => setState(true), []);

  const toggleOff = useCallback(() => setState(false), []);

  return [state, toggle, toggleOn, toggleOff];
};

export default useToggleState;
