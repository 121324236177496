import DescriptionIcon from '@mui/icons-material/Description';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useCallback, useState } from 'react';
import type { MouseEvent } from 'react';
import { useNavigate } from 'react-router';

export function InfoMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem('isAdmin');
  const selectedClient = localStorage.getItem('selectedClient');

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleUserNavigation = useCallback((value: any) => {
    navigate(`/${value}`);
    setAnchorEl(null);
  }, [navigate]);

  if (isAdmin === 'true' && selectedClient === 'null') {
    return null;
  }

  return (
    <Box alignItems="center" display="flex" ml={2}>
      <Tooltip title="Informazioni">
        <IconButton
          color="primary"
          size="small"
          sx={{ minWidth: 0 }}
          onClick={handleClick}
        >
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem sx={{ overflow: 'hidden', maxHeight: '80px', width: '200px' }} onClick={() => handleUserNavigation('documentiutili')}>
          <ListItemIcon>
            <DescriptionIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Documenti</ListItemText>
        </MenuItem>
      </Menu>
      {/* <Divider /> */}
      <Divider orientation="vertical" sx={{ ml: 2 }} flexItem />
    </Box>
  );
}
