import { DeleteTwoTone } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useParams } from 'react-router';

import { useDelete } from '../../../../../api/hooks/useDelete';
import { useFileContext } from '../../../../../pages/fascicolo/file/list/context';
import type { FascicoloFile } from '../../../../../types/fascicolo-file';
import withConfirmationButton from '../../../with-confirmation';

const ButtonWithConfirmation = withConfirmationButton(IconButton);

export function Delete(props: { file: FascicoloFile }) {
  const { fascicolo } = useParams();
  const queryClient = useQueryClient();
  const { forceRefresh } = useFileContext();

  const handleSuccess = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [`fascicolo/${fascicolo}/file`],
      type: 'all',
      refetchType: 'active',
    });
    forceRefresh?.();
  }, [fascicolo, forceRefresh, queryClient]);

  const { handleDelete } = useDelete({
    key: `fascicolo/${fascicolo}/file`,
    id: '',
    onSuccess: handleSuccess,
  });

  const onDelete = useCallback(
    () => handleDelete(props.file.id),
    [handleDelete, props.file],
  );

  return (
    <ButtonWithConfirmation
      buttonProps={{ color: 'success' }}
      message="Sei sicuro di volere confermare l'eliminazione?"
      onConfirm={onDelete}
    >
      <Tooltip placement="top" title="Elimina" followCursor>
        <DeleteTwoTone color="warning" />
      </Tooltip>
    </ButtonWithConfirmation>
  );
}
