import Box from '@mui/material/Box';
import { Route, Routes } from 'react-router';

import { NotificationContextProvider } from '../../common/contexts/notification-context';

import { Login } from './login';
import { RecuperaPassword } from './recupera-password';
import { RequestPassword } from './request-password';
import { RequestUsername } from './request-username';
import { authenticateCSS } from './styles';

export function Authenticate() {
  return (
    <NotificationContextProvider>
      <Box sx={authenticateCSS}>
        <Routes>
          <Route element={<Login />} path="*" />
          <Route
            element={<RecuperaPassword />}
            path="/recuperapassword/:token"
          />
          <Route element={<RequestPassword />} path="/recuperapassword" />
          <Route element={<RequestUsername />} path="/recuperausername" />
        </Routes>
      </Box>
    </NotificationContextProvider>
  );
}
