import { PictureAsPdfTwoTone, InsertDriveFileTwoTone, BorderAllTwoTone, InsertPhotoTwoTone, OndemandVideoTwoTone, ArchiveTwoTone } from '@mui/icons-material';

import type { FascicoloFile } from '../../../../../types/fascicolo-file';

export function FileIcon(props: { file: FascicoloFile }) {
  const fileExtension = props.file.link.split('.').pop();

  switch (fileExtension) {
    case 'pdf':
      return <PictureAsPdfTwoTone />;
    case 'doc':
    case 'docx':
      return <InsertDriveFileTwoTone />;
    case 'xls':
    case 'xlsx':
      return <BorderAllTwoTone />;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return <InsertPhotoTwoTone />;
    case 'mp4':
    case 'webm':
      return <OndemandVideoTwoTone />;
    default:
      return <ArchiveTwoTone />;
  }
}
