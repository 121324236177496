import type { SxProps } from '@mui/material';
import { Box, Card, Typography } from '@mui/material';
import type { ReactNode } from 'react';

export function EmptyCard(props: { children: ReactNode; sx?: SxProps }) {
  return (
    <Card sx={props.sx}>
      <Box p={3}>
        <Typography align="center" component="div" variant="body1">
          {props.children}
        </Typography>
      </Box>
    </Card>
  );
}
