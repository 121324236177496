import CancelIcon from '@mui/icons-material/Block';
import SaveIcon from '@mui/icons-material/Done';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export function Actions(props: { loading?: boolean; submitLabel?: string }) {
  const { reset, formState } = useFormContext();
  const handleReset = useCallback(() => reset(), [reset]);

  return (
    <Box display="flex" gap={1} justifyContent="flex-end" mt={2}>
      <Button
        disabled={!formState.isDirty || props.loading}
        startIcon={<CancelIcon />}
        variant="contained"
        onClick={handleReset}
      >
        Cancella
      </Button>
      <Button
        disabled={!formState.isValid || props.loading}
        startIcon={<SaveIcon />}
        type="submit"
        variant="contained"
      >
        {props.submitLabel ?? 'Salva'}
      </Button>
    </Box>
  );
}
