import MuiTextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import type { ComponentProps } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import type { UseFormRegister } from 'react-hook-form/dist/types/form';

import { useFieldError } from '../../hooks/useFieldError';
import { useFormFieldsContext } from '../../index';

import styles from './styles.module.css';

type DateFieldProps<TFieldValues extends FieldValues> = {
 TextInputProps?: Partial<ComponentProps<typeof MuiTextField>>;
 DatePickerProps?: Partial<ComponentProps<typeof DatePicker>>;
 required?: boolean;
 name: string;
 size?: string;
 register?: UseFormRegister<TFieldValues>;
};

export function DateField<TFieldValues extends FieldValues>(
  props: DateFieldProps<TFieldValues>,
) {
  const fieldContext = useFormFieldsContext();
  const { control, trigger } = useFormContext();
  const error = useFieldError(props.name);
  const format = props.DatePickerProps?.inputFormat;

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field }) => (
        <DatePicker
          key={field.value}
          className={props.size === 'small' ? styles.inputsmall : ''}
          disabled={fieldContext.disabled || props.DatePickerProps?.disabled}
          inputFormat="DD/MM/YYYY"
          renderInput={params => (
            <MuiTextField
              helperText={error}
              {...props.TextInputProps}
              {...params}
              error={!!error}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled',
              }}
              required={props.required}
            />
          )}
          value={field.value?.toString() || ''}
          onChange={data => {
            if (dayjs(data as Date, format ?? 'YYYY-MM-DD').isValid()) {
              field.onChange(
                data ? dayjs(data as Date).format(format ?? 'YYYY-MM-DD') : null,
              );
            }
            trigger(props.name);
          }}
          {...props.DatePickerProps}
        />
      )}
      rules={{ required: props.required }}
    />
  );
}
