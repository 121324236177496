import { LockOpenTwoTone, LockTwoTone } from '@mui/icons-material';
import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import type { ComponentProps } from 'react';
import { useMemo } from 'react';
import type { FieldValues, ValidationRule } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import type { UseFormRegister } from 'react-hook-form/dist/types/form';

import { useInputValidation } from '../../../../../configurations/validations';
import { useAction } from '../../../../hooks/use-action';
import useToggleState from '../../../../hooks/useToggleState';
import { useFieldError } from '../../hooks/useFieldError';
import { useFormFieldsContext } from '../../index';

type TextFieldProps<TFieldValues extends FieldValues> = ComponentProps<
  typeof MuiTextField
> & {
  name?: string;
  label?: string;
  register?: UseFormRegister<TFieldValues>;
  pattern?: false | ValidationRule<RegExp>;
  defaultEnabled?: boolean;
};

export function PasswordField<TFieldValues extends FieldValues>({
  defaultEnabled,
  ...props
}: TextFieldProps<TFieldValues>) {
  const fieldContext = useFormFieldsContext();
  const { control, trigger } = useFormContext();
  const error = useFieldError(props.name ?? 'password');
  const { isUpdate } = useAction();
  const [enabled, toggleEnabled] = useToggleState(defaultEnabled ?? false);

  const validations = useInputValidation({
    options: { enabled: props.pattern !== false },
  });
  const pattern = validations?.[props.name ?? 'password'];

  const passwordUpdate = useMemo(() => {
    if (isUpdate) {
      return (
        <InputAdornment position="end">
          <Tooltip title="Abilita modifica password">
            <IconButton onClick={toggleEnabled}>
              {enabled ? <LockOpenTwoTone /> : <LockTwoTone />}
            </IconButton>
          </Tooltip>
        </InputAdornment>
      );
    }

    return undefined;
  }, [isUpdate, enabled, toggleEnabled]);

  return (
    <Controller
      control={control}
      defaultValue=""
      name={props.name ?? 'password'}
      render={({ field }) => (
        <MuiTextField
          InputProps={{
            endAdornment: passwordUpdate,
          }}
          error={!!error}
          helperText={error}
          label={props.label ?? 'Password'}
          fullWidth
          {...fieldContext}
          {...field}
          {...props}
          disabled={(isUpdate && !enabled) || fieldContext.disabled}
          type="password"
          value={isUpdate && !enabled ? '*******' : field.value}
          onBlur={() => {
            trigger(props.name ?? 'password');
          }}
        />
      )}
      rules={{
        required: props.required,
        pattern: props.pattern === false ? undefined : props.pattern ?? pattern,
      }}
    />
  );
}
