import type { ComponentType } from 'react';
import { useCallback, Suspense, lazy, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useOutletContext } from 'react-router';
import { Outlet } from 'react-router-dom';

import Layout from '../common/components/layout';
import { LoadingPage } from '../common/components/loading-page';
import {
  RouterContextProvider,
  useRouterContext,
} from '../common/contexts/router-context';
import type { Route as TRoute } from '../configurations/routes/types';
import { useRoutes } from '../configurations/routes/useRoutes';
import Page404 from '../pages/404';
import LoadingScreen from '../pages/loading-screen';

export function Router() {
  const { data: routes, isLoading } = useRoutes();
  const isAdmin = localStorage.getItem('isAdmin');

  const getElement = useCallback((route: TRoute) => {
    const { children, componentPath } = route;
    const Component = lazy(() => import(`../${componentPath}`));

    if (children) {
      return <Outlet context={{ route }} />;
    }

    return <LazyComponent component={Component} route={route} />;
  }, []);

  const renderRoute = useCallback(
    (route: TRoute) => {
      const { index, path, children, redirect } = route;

      if (redirect) {
        return (
          <Route
            key={path + redirect}
            element={<Navigate to={redirect} replace />}
            path={path}
          />
        );
      }

      if (index) {
        return (
          <Route key={path} element={getElement(route)} path={path} index />
        );
      }

      return (
        <Route key={path} element={getElement(route)} path={path}>
          {children?.map(renderRoute)}
          <Route element={<Page404 />} path="*" />
        </Route>
      );
    },
    [getElement],
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <RouterContextProvider>
      <Routes>
        <Route element={<Layout />}>
          {routes.map(renderRoute)}
          <Route element={<Navigate to={isAdmin === 'true' ? '/dashboard' : '/mandati'} replace />} path="/" />
          <Route element={<Page404 />} path="*" />
        </Route>
      </Routes>
    </RouterContextProvider>
  );
}

function LazyComponent(props: { route: TRoute; component: ComponentType }) {
  const outletContext = useOutletContext<{ route: TRoute }>();
  const parentRoute = useMemo(
    () => outletContext?.route ?? {},
    [outletContext?.route],
  );

  const { setRoute } = useRouterContext();
  const Component = props.component;

  useEffect(() => {
    setRoute({ ...props.route, parentRoute });
  }, [parentRoute, props.route, setRoute]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Component />
    </Suspense>
  );
}
