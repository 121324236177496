import LibraryAddCheckTwoToneIcon from '@mui/icons-material/LibraryAddCheckTwoTone';
import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import MuiAutocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import type { ComponentProps, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import type { FieldValues, UseFormRegister } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

import { useFieldError } from '../../hooks/useFieldError';
import { useFormFieldsContext } from '../../index';

export type AutocompleteFieldOption = {
  key: string | number;
  value: string;
};

type AutocompleteFieldProps<TFieldValues extends FieldValues> = {
  TextInputProps?: Partial<ComponentProps<typeof MuiTextField>>;
  AutocompleteProps?: Partial<
    ComponentProps<typeof MuiAutocomplete<string | number, boolean>>
  >;
  disableClearable?: boolean;
  required?: boolean;
  name: string;
  options: AutocompleteFieldOption[];
  register?: UseFormRegister<TFieldValues>;
};

export function AutocompleteField<TFieldValues extends FieldValues>(
  props: AutocompleteFieldProps<TFieldValues>,
) {
  const fieldContext = useFormFieldsContext();
  const error = useFieldError(props.name);
  const { control, getValues, trigger, setValue } = useFormContext();

  const values = getValues(props.name);

  const options = useMemo(
    () => props.options.map(option => option.key) ?? [],
    [props.options],
  );

  const defaultValues = useMemo(() => values ?? undefined, [values]);
  const handleSelectAll = useCallback(() => {
    setValue(props.name, options);
    trigger(props.name);
  }, [options, props.name, setValue, trigger]);

  const isAllSelected =
    defaultValues && defaultValues.length === options.length;
  const canSelectAll = useMemo(
    () =>
      props.AutocompleteProps?.multiple &&
      props.options.length > 1 &&
      !props.AutocompleteProps.disabled &&
      !fieldContext.disabled &&
      !isAllSelected,
    [fieldContext.disabled, props, isAllSelected],
  );

  const selectAll = useCallback(
    (endAdornment: ReactNode) => (
      <>
        {endAdornment}

        <InputAdornment position="start">
          <Tooltip title="Seleziona tutti">
            <IconButton onClick={handleSelectAll}>
              <LibraryAddCheckTwoToneIcon />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      </>
    ),
    [handleSelectAll],
  );

  const getInputProps = useCallback(
    (params: {
      InputProps: {
        endAdornment: ReactNode;
      };
    }) => {
      if (canSelectAll) {
        return {
          ...params.InputProps,
          endAdornment: selectAll(params.InputProps.endAdornment),
        };
      }

      return params.InputProps;
    },
    [canSelectAll, selectAll],
  );

  return (
    <Controller
      key={values}
      control={control}
      defaultValue={props.AutocompleteProps?.multiple ? [] : null}
      name={props.name}
      render={({ field }) => (
        <MuiAutocomplete<string | number, boolean>
          defaultValue={defaultValues}
          getOptionLabel={option =>
            props.options?.find(o => o.key?.toString() === option?.toString())
              ?.value ?? ''
          }
          isOptionEqualToValue={(option, value) =>
            value.toString() === '' || option.toString() === value.toString()
          }
          options={options}
          renderInput={params => (
            <MuiTextField
              error={!!error}
              {...props.TextInputProps}
              helperText={error || props.TextInputProps?.helperText}
              {...params}
              InputProps={getInputProps(params)}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled',
              }}
              required={
                props.required && (!defaultValues || !defaultValues.length)
              }
              onBlur={() => trigger(props.name)}
            />
          )}
          fullWidth
          {...props.AutocompleteProps}
          disabled={fieldContext.disabled || props.AutocompleteProps?.disabled}
          onChange={(_, data, ...rest) => {
            field.onChange(data);
            trigger(props.name);
            props.AutocompleteProps?.onChange?.(_, data, ...rest);
          }}
        />
      )}
      rules={{ required: props.required }}
    />
  );
}
