import { Alert, Snackbar } from '@mui/material';

import { useNotification } from '../../contexts/notification-context';

export function Notification() {
  const { message, dismiss, severity } = useNotification();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={3000}
      open={!!message}
      onClose={dismiss}
    >
      <Alert severity={severity} sx={{ width: '100%' }} onClose={dismiss}>
        {message}
      </Alert>
    </Snackbar>
  );
}
