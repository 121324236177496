import { HomeTwoTone } from '@mui/icons-material';
import { capitalize, Link } from '@mui/material';
import Box from '@mui/material/Box';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { useRoutes } from '../../../../configurations/routes/useRoutes';

export function Breadcrumbs() {
  const { data: routes } = useRoutes();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(Boolean);

  if (!routes) {
    return null;
  }

  return (
    <Box p={1}>
      <MuiBreadcrumbs maxItems={3} separator="›">
        <Link
          color="inherit"
          component={RouterLink}
          sx={{ display: 'flex', height: 24, alignItems: 'center' }}
          to="/"
        >
          <HomeTwoTone fontSize="small" />
        </Link>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `${pathnames.slice(0, index + 1).join('/')}`;

          if (last) {
            return (
              <Typography key={to} color="text.primary">
                <RouteLabel value={value} />
              </Typography>
            );
          }

          return (
            <Link key={to} color="inherit" component={RouterLink} to={`/${to}`}>
              <RouteLabel value={value} />
            </Link>
          );
        })}
      </MuiBreadcrumbs>
    </Box>
  );
}

function flattenRoutes<T extends { path?: string; children?: T[] }>(
  array?: T[],
  prefix = '',
): T[] {
  if (!array) return [];

  return array.reduce((flattened, element) => {
    flattened.push({
      ...element,
      path: `${prefix ? `${prefix}/` : ''}${element.path}`,
    });
    if (element.children) {
      element.children.forEach(child =>
        flattened.push(
          ...flattenRoutes(
            [child],
            `${prefix ? `${prefix}/` : ''}${element.path}`,
          ),
        ),
      );
    }

    return flattened;
  }, [] as T[]);
}

enum Actions {
  update = 'Modifica',
  add = 'Aggiungi',
  show = 'Visualizza',
}
function RouteLabel(props: { value: string }) {
  const { data: routes } = useRoutes();
  const action = Actions[props.value as keyof typeof Actions];
  const flatten = flattenRoutes(routes);
  const collection = flatten.find(r => r.path === props.value)?.label;

  return (
    <Typography component="span">
      {collection ?? action ?? capitalize(props.value)}
    </Typography>
  );
}
