import type { QueryFunctionContext } from '@tanstack/react-query';

import { instance } from '../axios';
import type { ManyQueryKey } from '../types/params';

type Response<R> = {
  data: {
    risultati: R[];
    totale?: number;
    pagina?: number;
  };
};

export async function getMany<R, F>({
  queryKey: [key, params],
}: QueryFunctionContext<ManyQueryKey<F>>) {
  const {
    data: { risultati },
  } = await instance.get<R, Response<R>>(`${key}/`, {
    params,
  });

  return risultati;
}
