import type { ReactElement } from 'react';
import { useCallback } from 'react';
import type { DeepPartial } from 'react-hook-form';

import { useGetOne } from '../../../api/hooks/useGetOne';
import { useMutations } from '../../../api/hooks/useMutations';
import { ErrorBoundary } from '../error-boundary';
import { Form } from '../form';
import { FormErrors } from '../form-errors';

import { Actions } from './actions';

type InlineFormProps<R, P> = {
  id?: string | number;
  source: string;
  disabled?: boolean;
  actionVisible?: boolean | null | undefined;
  children: ReactElement | ReactElement[];
  onSuccess?: (response: any) => void;
  dataParser?: (data: R) => P;
  submitDataParser?: (data: P) => DeepPartial<P>;
  defaultValues?: DeepPartial<P>;
  handleSubmit?: (data: P) => void;
  skipGet?: boolean;
};

export default function InlineForm<R extends Record<string, any>, P = R>(
  props: InlineFormProps<R, P>,
) {
  const { data, isFetching } = useGetOne<R, P>(props.source, props.id ?? '', {
    dataParser: props.dataParser,
    enabled: !!props.source && !!props.id && !props.skipGet,
  });

  const {
    mutate,
    error,
    isLoading: isMutating,
  } = useMutations({
    id: props.id,
    onSuccess: props.onSuccess,
    key: props.source,
  });

  const handleSubmit = useCallback(
    (values: R) => {
      const parsed = JSON.parse(
        JSON.stringify(values, (key, value) => (value === '' ? null : value)),
      );

      if (props.handleSubmit) {
        props.handleSubmit(parsed);

        return;
      }

      if (props.submitDataParser) {
        mutate({ data: props.submitDataParser(parsed) });
      } else {
        mutate({ data: parsed });
      }
    },
    [mutate, props],
  );

  return (
    <ErrorBoundary>
      <Form<R>
        defaultValues={
          { ...props.defaultValues, ...data } as DeepPartial<R> | undefined
        }
        disabled={props.disabled}
        onSubmit={handleSubmit}
      >
        {props.children}
        { props.actionVisible !== true && !props.disabled && <Actions loading={isFetching || isMutating} />}
        <FormErrors error={error} />
      </Form>
    </ErrorBoundary>
  );
}
