import { AddCircleTwoTone } from '@mui/icons-material';
import { Button } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useCallback, useRef } from 'react';

export function FileInput(props: { onSelectedFile: (file: File) => void }) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0];
      if (!selectedFile) {
        return;
      }
      props.onSelectedFile(selectedFile);
    },
    [props],
  );

  return (
    <>
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
        onChange={handleChange}
      />

      <Button
        color="success"
        startIcon={<AddCircleTwoTone />}
        sx={{ width: 200 }}
        variant="contained"
        onClick={handleClick}
      >
        Aggiungi file
      </Button>
    </>
  );
}
