const urlExclude = ['notifica/', 'refresh/'];

export function requestSuccessInterceptor(axiosRequestCfg: any) {
  const result = urlExclude.some((word) => !!axiosRequestCfg.url.includes(word));

  if (!result) {
    document.body.classList.add('loading-indicator');
  }

  // Check and acquire a token before the request is sent
  try {
    const token = localStorage.getItem('token');
    const client = localStorage.getItem('selectedClient');
    const clients = localStorage.getItem('clients');
    const isAdmin = localStorage.getItem('isAdmin');

    const headers = {
      ...axiosRequestCfg.headers,
      Authorization: token && token !== 'null' && `Bearer ${JSON.parse(token)}`,
    };

    if (!clients) {
      throw new Error('No clients found');
    }

    if (!isAdmin || isAdmin === 'false') {
      try {
        // eslint-disable-next-line prefer-destructuring
        headers['X-Cliente'] = JSON.parse(clients)?.[0];
      } catch (e) {
        console.error(e);
      }
    }

    if (isAdmin && client && client !== 'null') {
      headers['X-Cliente'] = client;
    }

    if (axiosRequestCfg.headers) {
      return {
        ...axiosRequestCfg,
        headers,
      };
    }
  } catch (err) {
    // Do something with error of acquiring the token
    console.error('getAuthToken:', err);
  }

  return axiosRequestCfg;
}

export function MockDataMapper(response: any) {
  if (Array.isArray(response.data)) {
    return {
      ...response,
      data: {
        risultati: response.data,
        totale: response.headers['x-total-count'],
        pagina: 1,
      },
    };
  }

  return response;
}

export function PaginationRequestMapper(request: any) {
  /* eslint-disable no-underscore-dangle */
  return {
    ...request,
    params: {
      ...request.params,
      pag_inizio:
        request?.params?._start === undefined
          ? undefined
          : request.params._start,
      _start: undefined,
      pag_fine:
        request?.params?._end === undefined ? undefined : request.params._end,
      _end: undefined,
    },
  };
  /* eslint-enable no-underscore-dangle */
}
