import { Box, Skeleton } from '@mui/material';

import { times } from '../../../../../../../utils/array';

export function ElaboratoLoading() {
  return (
    <Box>
      {times(4, i => (
        <Box key={i} alignItems="center" display="flex" gap={1} my={1}>
          <Skeleton height={30} variant="rounded" width="100%" />
        </Box>
      ))}
    </Box>
  );
}
