import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

import useToggleState from '../../hooks/useToggleState';

type Context = {
  open: boolean;
  handleToggleDrawer: () => void;
  handleToggleOpen: () => void;
  handleToggleClose: () => void;
};

export const AppDrawerContext = createContext<Context>({} as Context);

export function AppDrawerContextProvider(props: { children: ReactNode }) {
  const [open, handleToggleDrawer, handleToggleOpen, handleToggleClose] =
    useToggleState(false);

  const contextValue = useMemo(
    () => ({
      open,
      handleToggleDrawer,
      handleToggleOpen,
      handleToggleClose,
    }),
    [open, handleToggleDrawer, handleToggleOpen, handleToggleClose],
  );

  return (
    <AppDrawerContext.Provider value={contextValue}>
      {props.children}
    </AppDrawerContext.Provider>
  );
}

export function useAppDrawerContext() {
  return useContext(AppDrawerContext);
}
