import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import { Authenticate } from '../../../auth/authenticate';
import { useToken } from '../../../auth/hooks/useToken';

type Context = ReturnType<typeof useToken>;

export const AuthContext = createContext<Context>({} as Context);

export function AuthContextProvider(props: { children: ReactNode }) {
  const context = useToken();

  return (
    <AuthContext.Provider value={context}>
      {context.token ? props.children : <Authenticate />}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
