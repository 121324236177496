import { CheckTwoTone, OpenInNewTwoTone } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { FileElaborato } from '../../../../../../../types/fascicolo-file';
import withConfirmationButton from '../../../../../with-confirmation';

const ButtonWithConfirmation = withConfirmationButton(Button);

export function ProcessSentenzaFallimento(props: { file: FileElaborato; onClose: () => void }) {
  const { fascicolo } = useParams();
  const navigate = useNavigate();

  const handleGoToCreateGaranti = useCallback(() => {
    const path = `/fascicolo/${fascicolo}/altraprocedura/add`;

    navigate(path, { state: props.file?.altraprocedura });
    props.onClose();
  }, [fascicolo, navigate, props]);

  const handleGoToLinkedGaranti = useCallback(() => {
    const path = `/fascicolo/${fascicolo}/altraprocedura/${props.file?.link_altraprocedura}`;

    navigate(path);
    props.onClose();
  }, [fascicolo, props, navigate]);

  // If the file has no title the file cannot be proccessed
  if (!Object.values(props.file.altraprocedura ?? {}).length) {
    return null;
  }

  if (props.file.link_altraprocedura) {
    return (
      <Button
        color="success"
        startIcon={<OpenInNewTwoTone />}
        variant="contained"
        onClick={handleGoToLinkedGaranti}
      >
        Vai alla procedura fallimentare collegata
      </Button>
    );
  }

  return (
    <ButtonWithConfirmation
      buttonProps={{
        color: 'success',
        variant: 'contained',
        startIcon: <CheckTwoTone />,
      }}
      message="Vuoi procedere alla creazione della nuova procedura fallimentare?"
      onConfirm={handleGoToCreateGaranti}
    >
      Processa
    </ButtonWithConfirmation>
  );
}
