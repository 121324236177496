import _currency from 'currency.js';

export function currency(value: number | string) {
  return _currency(value, {
    symbol: '€',
    separator: '.',
    decimal: ',',
    pattern: '# !',
  }).format();
}

export function currencyNoSymbol(value: number | string) {
  return _currency(value, {
    symbol: '',
    separator: '.',
    decimal: ',',
    pattern: '# !',
  }).format();
}

export function toNumber(value: _currency) {
  return _currency(value, {
    symbol: '€',
    separator: '.',
    decimal: ',',
    pattern: '# !',
  });
}
