import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { useAuthContext } from '../contexts/auth-context';
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 * @param promptTime - number of seconds to wait before user is notified of idle timeout
 */
export function useIdleTimeout({
  onIdle,
  idleTime = 1,
  promptTime = 0.5,
}: {
  onIdle: () => void;
  idleTime?: number;
  promptTime?: number;
}) {
  const idleTimeout = 1000 * idleTime;
  const promptTimeout = 1000 * promptTime;

  const [isIdle, setIdle] = useState(false);
  const { logout } = useAuthContext();
  const handleIdle = () => {
    setIdle(true);
    logout();
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: promptTimeout,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
  });

  return {
    isIdle,
    setIdle,
    idleTimer,
  };
}
export default useIdleTimeout;
