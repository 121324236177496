import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import type { MutationError } from '../../../api/hooks/useMutations';

export function FormErrors({ error }: { error: MutationError | null }) {
  const { setError, clearErrors } = useFormContext();

  useEffect(() => {
    clearErrors();

    const errorData = Array.isArray(error?.response?.data)
      ? error?.response?.data
      : [error?.response?.data];

    if (errorData) {
      errorData?.forEach(err => {
        if (err) {
          toast.error(
            [err.campo_errore, err.messaggio_errore]
              .filter(Boolean)
              .join(': ') ?? 'Errore generico',
            {
              position: 'top-right',
              autoClose: 10000,
              theme: 'colored',
            },
          );

          setError(err.campo_errore, {
            message: err.messaggio_errore,
          });
        }
      });
    }
  }, [setError, error, clearErrors]);

  return null;
}
