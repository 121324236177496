import type { ComponentProps } from 'react';

import type { useGetMany } from '../../../../../api/hooks/useGetMany';
import { useDomainOptions } from '../../../../../configurations/domains';
import type {
  Domains,
  DomainItem,
} from '../../../../../configurations/domains/types';
import { AutocompleteField } from '../autocomplete-field';

type Props = Pick<
  ComponentProps<typeof AutocompleteField>,
  'AutocompleteProps' | 'TextInputProps'
> & {
  name: string;
  inputLabel: string;
  required?: boolean;
  domain: Domains;
  filters?: Record<string, any>;
  options?: Parameters<
    typeof useGetMany<Record<string, any> | undefined, DomainItem>
  >[2];
  customOptions?: { key: string; value: string }[];
};

export function StaticAutoComplete(props: Props) {
  const { options, isLoading } = useDomainOptions(
    props.domain,
    props.filters,
    props.options,
  );
  const label = props.inputLabel;

  return (
    <AutocompleteField
      AutocompleteProps={{
        ...props.AutocompleteProps,
        disabled: isLoading || props.AutocompleteProps?.disabled,
      }}
      TextInputProps={{ label }}
      name={props.name}
      options={[...(props.customOptions ?? []), ...options]}
      required={props.required}
    />
  );
}
