import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { useCallback, useEffect } from 'react';

import { useNotification } from '../../common/contexts/notification-context';
import { deleteJson } from '../delete/deleteJson';

type UseDeleteParams = {
  id: string | number;
  key: string;
  onSuccess?: (response: unknown) => void;
};

export type DeleteError = Error & {
  response: { data: { messaggio_errore: string; campo_errore: string }[] };
};

export function useDelete(params: UseDeleteParams) {
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  const deleteMutation = useMutation<
    AxiosResponse<{ id: string | number }, DeleteError>,
    DeleteError,
    { path: string; id: string | number }
  >([params.key], deleteJson);

  const reset = useCallback(() => {
    deleteMutation.reset();
  }, [deleteMutation]);

  const handleDelete = useCallback(
    (id: string | number) => {
      reset();
      deleteMutation.mutate({ id, path: params.key });
    },
    [reset, deleteMutation, params.key],
  );

  const { isError, isLoading, isSuccess, error, data } = deleteMutation;

  useEffect(() => {
    if (isSuccess) {
      if (params.onSuccess) {
        params.onSuccess(data?.data);
      }

      reset();
      queryClient
        .invalidateQueries({
          queryKey: [params.key],
          refetchType: 'active',
          type: 'all',
        })
        .then(() => {
          notify('Eliminazione effettuata con successo', 'success');
        });
    }
  }, [data?.data, isSuccess, notify, params, queryClient, reset]);

  useEffect(() => {
    if (isError) {
      notify(
        error?.message ??
          'Ooops.. Qualcosa è andato storto, riprova più tardi!',
        'error',
      );
    }
  }, [isError, error, notify]);

  return {
    isError,
    isLoading,
    isSuccess,
    reset,
    error,
    data,
    handleDelete,
  };
}
