import { Box, Card, Typography } from '@mui/material';
import { useParams } from 'react-router';

import { TextField } from '../../../../common/components/form/fields/text-field';
import InlineForm from '../../../../common/components/inline-form';

export function NewMessage() {
  const { fascicolo } = useParams();

  return (
    <Card sx={{ p: 2 }}>
      <Typography mb={2} variant="h4">
        Nuovo messaggio
      </Typography>
      <InlineForm source={`fascicolo/${fascicolo}/messaggio`}>
        <Box>
          <TextField label="Messaggio" name="contenuto" rows={4} multiline />
        </Box>
      </InlineForm>
    </Card>
  );
}
