import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import type { ReactElement, ReactNode } from 'react';

import { ErrorBoundary } from '../error-boundary';

type PageLayoutProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  children: ReactElement | ReactElement[];
  actions?: ReactElement | ReactElement[];
  meta?: ReactNode;
};
export function PageLayout(props: PageLayoutProps): ReactElement {
  return (
    <ErrorBoundary>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">{props.title}</Typography>
          {props.subtitle && (
            <Typography component="div" sx={{ my: 1 }}>
              {props.subtitle}
            </Typography>
          )}
        </Box>
        <Stack alignItems="end" gap={1}>
          <Box>{props.actions}</Box>
          <Box textAlign="right">{props.meta}</Box>
        </Stack>
      </Box>
      <Divider sx={{ my: 2 }} />
      {props.children}
    </ErrorBoundary>
  );
}
