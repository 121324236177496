import { capitalize } from '@mui/material';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';

export function useFieldError(name: string) {
  const {
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);

  if (!get(errors, name)) {
    return undefined;
  }

  const errorMessage = error?.message;
  if (errorMessage) {
    return capitalize(errorMessage.toString());
  }

  if (error?.type === 'required') {
    // return `${capitalize(name)} è un campo obbligatorio`;
    return 'Campo obbligatorio';
  }

  return 'Campo non valido';
}
