import { AccountCircleTwoTone, LogoutTwoTone } from '@mui/icons-material';
import { Badge, Button, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { MouseEvent } from 'react';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';

import { useAuthContext } from '../../../../contexts/auth-context';

import { IdleModal } from './idle-modal';
import { SelectClient } from './select-client';

export function AccountMenu() {
  const [client] = useLocalStorage<null | number>('selectedClient', null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { logout, username, userId } = useAuthContext();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleUserNavigation = useCallback(() => {
    navigate(`/profilo/${userId}`);
    setAnchorEl(null);
  }, [navigate, userId]);

  return (
    <Box ml={2}>
      <Tooltip title={client ? 'Stai operando per conto di un cliente' : null}>
        <Badge badgeContent={client ? '!' : null} color="primary">
          <Button
            color="secondary"
            sx={{ minWidth: 0 }}
            variant="contained"
            onClick={handleClick}
          >
            <AccountCircleTwoTone />
          </Button>
        </Badge>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleUserNavigation}>
          <ListItemText>
            Benvenuto {username ?? `Utente ${userId}`}
          </ListItemText>
        </MenuItem>

        <SelectClient />
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutTwoTone fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
      <IdleModal />
    </Box>
  );
}
