export const hexToFilter = {
  '#5569ff':
    'invert(44%) sepia(54%) saturate(6757%) hue-rotate(225deg) brightness(108%) contrast(101%)',
  '#162138':
    'invert(9%) sepia(48%) saturate(1107%) hue-rotate(187deg) brightness(95%) contrast(91%)',
  '#b1d8f8':
    'invert(99%) sepia(73%) saturate(2240%) hue-rotate(171deg) brightness(100%) contrast(95%)',
  '#ee2b23':
    'invert(23%) sepia(96%) saturate(5075%) hue-rotate(351deg) brightness(103%) contrast(89%)',
  '#670e0b':
    'invert(10%) sepia(69%) saturate(3677%) hue-rotate(350deg) brightness(84%) contrast(100%)',
  '#e8615d':
    'invert(54%) sepia(12%) saturate(2761%) hue-rotate(314deg) brightness(94%) contrast(94%)',
  '#f4f5fa':
    'invert(98%) sepia(2%) saturate(478%) hue-rotate(193deg) brightness(99%) contrast(97%)',
  '#070f11':
    'invert(3%) sepia(5%) saturate(6400%) hue-rotate(140deg) brightness(98%) contrast(95%)',
  '#ffffff':
    'invert(100%) sepia(100%) saturate(2%) hue-rotate(274deg) brightness(104%) contrast(101%)',
};
