import EditNoteIcon from '@mui/icons-material/EditNote';
import { Box, IconButton, Tooltip } from '@mui/material';

import type { FascicoloFile } from '../../../../../types/fascicolo-file';
import useToggleState from '../../../../hooks/useToggleState';

import { ModifyDialog } from './modify-dialog';

export function Modify(props: { file: FascicoloFile }) {
  const [open, toggleOpen] = useToggleState(false);

  if (props.file === null || props.file === undefined || props.file.show_validate !== 1) {
    return null;
  }

  if (props.file.can_validate !== 1) {
    return <Box sx={{ width: '40px', display: 'inline-block' }} />;
  }

  return (
    <>
      <IconButton onClick={toggleOpen}>
        <Tooltip placement="top" title="Convalida" followCursor>
          <EditNoteIcon color="primary" />
        </Tooltip>
      </IconButton>
      <ModifyDialog file={props.file} open={open} onClose={toggleOpen} />
    </>
  );
}
