import { useState } from 'react';

import { useResizableDrawerContext } from '../../contexts/resizable-drawer-context';

import { ResizeHandle } from './resize-handle';
import { StyledDrawer } from './styles';

export function ResizableDrawer() {
  const [isDragging, setIsDragging] = useState(false);
  const { open, component } = useResizableDrawerContext();
  const { width } = useResizableDrawerContext();

  if (!component) return null;

  return (
    <StyledDrawer
      anchor="right"
      isDragging={isDragging}
      open={open}
      variant="permanent"
      width={width}
    >
      <ResizeHandle
        onDragEnd={() => setIsDragging(false)}
        onDragStart={() => setIsDragging(true)}
      />
      {component}
    </StyledDrawer>
  );
}
