import { Refresh } from '@mui/icons-material';
import type { SxProps } from '@mui/material';
import { Box, Button } from '@mui/material';
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { EmptyCard } from '../empty-card';

function ErrorFallback({
  error,
  resetErrorBoundary,
  sx,
}: {
  error: Error;
  resetErrorBoundary: () => void;
  sx: SxProps;
}) {
  return (
    <Box role="alert" sx={{ my: 2, ...sx }}>
      <EmptyCard>
        Ooops! Houston, we have a problem. 😨
        <pre>{error.message}</pre>
        <Button variant="outlined" onClick={resetErrorBoundary}>
          <Refresh fontSize="small" sx={{ mr: 2 }} /> Riprova
        </Button>
      </EmptyCard>
    </Box>
  );
}

export function ErrorBoundary({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) {
  const [errorKey, setErrorKey] = useState<number>(0);

  const renderFallback = useCallback(
    (args: any) => <ErrorFallback sx={sx} {...args} />,
    [sx],
  );

  return (
    <ReactErrorBoundary
      FallbackComponent={renderFallback}
      resetKeys={[errorKey]}
      onReset={() => setErrorKey(errorKey + 1)}
    >
      {children}
    </ReactErrorBoundary>
  );
}
