import { Box, Card } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-community';
import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import type {
  FascicoloFilePost,
  FilesDetails,
} from '../../../../types/fascicolo-file';
import { DataGrid } from '../../data-grid';
import { optionsColumnPartial } from '../../data-grid/col-def-partials/options-column-partial';

import { columns } from './columns';
import { FileInput } from './file-input';

export function FileContent() {
  const { control, getValues } = useFormContext<FascicoloFilePost>();
  const { filesDetails } = getValues();
  const { append: appendFileDetails, remove: removeFileDetails } =
    useFieldArray({
      control,
      name: 'filesDetails',
    });

  const handleDeleteFile = useCallback(
    (params: ICellRendererParams) => {
      removeFileDetails(params.rowIndex);
    },
    [removeFileDetails],
  );

  const handleSelectedFile = useCallback(
    (file: File) => {
      appendFileDetails({
        file,
        fascicolofilecategoria: undefined,
        filename: file.name,
        descrizione: file.name,
      });
    },
    [appendFileDetails],
  );

  const actionsColumn = optionsColumnPartial({
    delete: true,
    onDelete: handleDeleteFile,
  });

  return (
    <Box width="70vw">
      <Box display="flex" justifyContent="end" my={2}>
        <FileInput onSelectedFile={handleSelectedFile} />
      </Box>
      <Card>
        <DataGrid<FilesDetails>
          columnDefs={[...columns, actionsColumn]}
          rowData={filesDetails ?? []}
        />
      </Card>
    </Box>
  );
}
