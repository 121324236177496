import type { Dispatch, ReactNode, SetStateAction } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type Context = {
  width: number;
  setWidth: Dispatch<SetStateAction<number>>;
  open: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  component: ReactNode;
  setComponent: Dispatch<SetStateAction<ReactNode>>;
};

export const ResizableDrawerContext = createContext<Context>({} as Context);

export function ResizableDrawerContextProvider(props: { children: ReactNode }) {
  const [width, setWidth] = useState(300);
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState<ReactNode>(null);

  const openDrawer = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setComponent(null);
    setOpen(false);
  }, []);

  const contextValue = useMemo(
    () => ({
      width,
      setWidth,
      open,
      component,
      setComponent,
      openDrawer,
      closeDrawer,
    }),
    [width, open, component, openDrawer, closeDrawer],
  );

  return (
    <ResizableDrawerContext.Provider value={contextValue}>
      {props.children}
    </ResizableDrawerContext.Provider>
  );
}

export function useResizableDrawerContext() {
  return useContext(ResizableDrawerContext);
}
