import { Icon } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { CSSProperties } from 'react';

import { hexToFilter } from './colors-to-filter';

const StyledIcon = styled(Icon, {
  shouldForwardProp(propName: PropertyKey) {
    return propName !== 'customColor';
  },
})<{ customColor?: string }>`
  filter: ${props =>
    props.customColor
      ? hexToFilter[props.customColor as keyof typeof hexToFilter]
      : ''};
  color: ${props => (props.customColor ? '#000000' : '')};
`;

export function FontIcon(props: {
  icon: string;
  color?: string;
  style?: CSSProperties;
}) {
  const { icon } = props;

  return (
    <StyledIcon
      baseClassName="material-icons-two-tone"
      customColor={props.color}
      style={props.style}
    >
      {icon}
    </StyledIcon>
  );
}
