import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

import type { MenuItem } from '../../../configurations/menu/types';
import { useMenus } from '../../../configurations/menu/useMenus';

type Context = {
  menuItems: MenuItem[];
  activeMenuItem: MenuItem | null;
  activeSection: MenuItem | null;
  setActiveMenuItem: Dispatch<SetStateAction<MenuItem | null>>;
  setActiveSection: (menuItem: MenuItem | null) => void;
};

export const MenuContext = createContext<Context>({} as Context);

export function MenuContextProvider(props: { children: ReactNode }) {
  const { data } = useMenus();
  const menuItems = useMemo(() => data?.menu ?? [], [data]);

  const [activeMenuItem, setActiveMenuItem] = useState<MenuItem | null>(null);
  const [activeSection, setActiveSection] = useState<MenuItem | null>(null);

  const contextValue = useMemo(
    () => ({
      menuItems,
      activeMenuItem,
      activeSection,
      setActiveMenuItem,
      setActiveSection,
    }),
    [activeMenuItem, activeSection, menuItems],
  );

  return (
    <MenuContext.Provider value={contextValue}>
      {props.children}
    </MenuContext.Provider>
  );
}

export function useMenuContext() {
  return useContext(MenuContext);
}
