import { Alert, Divider, Paper, Typography } from '@mui/material';

import type { FileElaborato } from '../../../../../../../types/fascicolo-file';

import { NuovaSentenzaFallimentoFormForm } from './nuova-sentenza-fallimento-form';

export function NuovaSentenzaFallimento(props: { file: FileElaborato; fileId: number }) {
  if (!props.file.soggetti_nuovi?.length || props.file.link_titolo) return null;

  return (
    <Paper sx={{ mt: 2 }} variant="outlined">
      <Typography sx={{ p: 2 }} variant="h5">
        Nuovi Soggetti
      </Typography>
      <Divider />
      <Alert severity="warning" sx={{ p: 2, m: 2, mb: 1 }}>
        Sono stati trovati nuovi soggetti all'interno della sentenza di fallimento, per poter procedere con il processamento è necessario creare i soggetti collegati alla procedura fallimentare.
      </Alert>
      <NuovaSentenzaFallimentoFormForm file={props.file} fileId={props.fileId} />
    </Paper>
  );
}
