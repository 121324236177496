import {
  ControlPointTwoTone,
  DocumentScannerTwoTone,
  Message,
} from '@mui/icons-material';
import {
  Backdrop,
  Box,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material';
import type { ComponentType, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { useQuickActionsDrawerContext } from '../../contexts/quick-actions-drawer-context';
import useToggleState from '../../hooks/useToggleState';

import { FileManager } from './file-manager';
import { Messages } from './messages';

export enum QuickAction {
  FILE_MANAGER = 'filemanager',
  MESSAGES = 'messages',
}

export const QuickActionsMap: Record<
  QuickAction,
  {
    title: string;
    icon: ReactNode;
    action: QuickAction;
    component: ComponentType<{}>;
  }
> = {
  filemanager: {
    title: 'Gestione file',
    icon: <DocumentScannerTwoTone />,
    action: QuickAction.FILE_MANAGER,
    component: FileManager,
  },
  messages: {
    title: 'Messaggi',
    icon: <Message />,
    action: QuickAction.MESSAGES,
    component: Messages,
  },
};

export function QuickActions(props: { actions: QuickAction[] }) {
  const { handleCloseDrawer, handleSelectAction } =
    useQuickActionsDrawerContext();

  const [visible, , makeVisible, makeInvisible] = useToggleState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => handleCloseDrawer, [handleCloseDrawer]);

  if (!props.actions.length) return null;

  return (
    <>
      <Backdrop open={open} />
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: 40,
          height: 80,
        }}
        onMouseEnter={makeVisible}
        onMouseLeave={makeInvisible}
      />
      <SpeedDial
        ariaLabel="Quick actions"
        icon={<SpeedDialIcon icon={<ControlPointTwoTone />} />}
        open={open}
        sx={{
          transition: 'all 0.3s ease-in-out',
          position: 'fixed',
          bottom: 16,
          right: visible ? -5 : -50,

          '& > .MuiButtonBase-root': {
            borderRadius: '30px 0 0 30px',
            height: 30,
            justifyContent: 'start',
            paddingLeft: 1,
            width: 85,
          },
        }}
        onClose={handleClose}
        onMouseEnter={makeVisible}
        onMouseLeave={makeInvisible}
        onOpen={handleOpen}
      >
        {props.actions.map(action => (
          <SpeedDialAction
            key={action}
            icon={QuickActionsMap[action].icon}
            tooltipTitle={QuickActionsMap[action].title}
            onClick={() => handleSelectAction(action)}
          />
        ))}
      </SpeedDial>
    </>
  );
}
