import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo } from 'react';
import type { DeepPartial, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

type FormFieldContext = { disabled?: boolean };
export const FormFieldsContext = createContext<FormFieldContext>(
  {} as FormFieldContext,
);

type FormProps<TFormValues extends Record<string, any>> = FormFieldContext & {
  onSubmit: SubmitHandler<TFormValues>;
  children: ReactNode;
  defaultValues?: DeepPartial<TFormValues>;
  options?: Parameters<typeof useForm<TFormValues>>[0];
};

export function Form<TFormValues extends Record<string, any>>(
  props: FormProps<TFormValues>,
) {
  const { pathname } = useLocation();
  const methods = useForm<TFormValues>({
    mode: 'onBlur',
    defaultValues: props.defaultValues,
    ...props.options,
  });

  const values = useMemo(
    () => ({ disabled: props.disabled }),
    [props.disabled],
  );

  useEffect(() => {
    methods.reset(props.defaultValues);
  }, [methods, props.defaultValues]);

  useEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset(props.defaultValues);
    }
  }, [methods, pathname, props.defaultValues]);

  return (
    <FormProvider<TFormValues> {...methods}>
      <FormFieldsContext.Provider value={values}>
        <form
          onSubmit={e => {
            methods.clearErrors();
            methods.handleSubmit(props.onSubmit)(e);
            e.stopPropagation();
          }}
        >
          {props.children}
        </form>
      </FormFieldsContext.Provider>
    </FormProvider>
  );
}

export function useFormFieldsContext() {
  return useContext(FormFieldsContext);
}
