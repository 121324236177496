import type { Theme } from '@mui/material';

export function FileActionsCSS(theme: Theme) {
  return (
    {
      pr: 1,
      background: theme.palette.background.light,
      boxShadow: `-10px 0px 15px ${theme.palette.background.light}`,
    }
  );
}
