import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageLayout } from '../../common/components/page-layout';

export default function Page404() {
  const navigate = useNavigate();

  const onBack = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  return (
    <PageLayout
      subtitle="Il contenuto che stai cercando non sembra essere più disponibile"
      title="404"
    >
      <Button variant="contained" onClick={onBack}>
        Torna alla home
      </Button>
    </PageLayout>
  );
}
