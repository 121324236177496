import type { ICellRendererParams } from 'ag-grid-community';

import { Domains } from '../../../../configurations/domains/types';
import { StaticOptionCellRenderer } from '../../data-grid/renderers/static-option-cell-renderer';

export const columns = [
  {
    field: 'fascicolofilecategoria',
    headerName: 'Categoria',
    cellRenderer: (params: ICellRendererParams) => (
      <StaticOptionCellRenderer
        domain={Domains.FASCICOLO_FILE_CATEGORIA}
        field="fascicolofilecategoria"
        index={params.rowIndex}
        name="filesDetails"
      />
    ),
  },
  { field: 'filename', headerName: 'Nome' },
  { field: 'descrizione', headerName: 'Descrizione', editable: true },
];
