import { useDomainOptions } from '../../../../../configurations/domains';
import type { Domains } from '../../../../../configurations/domains/types';
import type { OptionCellRendererProps } from '../option-cell-renderer';
import { OptionCellRenderer } from '../option-cell-renderer';

type StaticOptionCellRendererProps = Omit<
  OptionCellRendererProps<Record<string, any>>,
  'options' | 'getOptionLabel' | 'isLoading' | 'keyField'
> & {
  domain: Domains;
};

export function StaticOptionCellRenderer({
  domain,
  ...props
}: StaticOptionCellRendererProps) {
  const { options, isLoading } = useDomainOptions(domain);

  return (
    <OptionCellRenderer
      disabled={props.disabled}
      getOptionLabel={option => getOptionLabel(option, options)}
      isLoading={isLoading}
      keyField="key"
      options={options}
      {...props}
    />
  );
}

function getOptionLabel(
  option: string | number | { value: string; key: number },
  options: { key: number; value: string }[],
) {
  if (typeof option === 'string' || typeof option === 'number') {
    return options.find(opt => opt.key === option)?.value ?? option.toString();
  }

  return option?.value.toString();
}
