import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type Context = {
  key: number;
  forceRefresh: () => void;
};

export const FileContext = createContext<Context>({} as Context);

export function FileContextProvider(props: { children: ReactNode }) {
  const [key, setKey] = useState(0);
  const forceRefresh = useCallback(() => setKey(prev => prev + 1), []);

  const contextValue = useMemo(
    () => ({
      key,
      forceRefresh,
    }),
    [forceRefresh, key],
  );

  return (
    <FileContext.Provider value={contextValue}>
      {props.children}
    </FileContext.Provider>
  );
}

export function useFileContext() {
  return useContext(FileContext);
}
