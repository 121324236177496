import { useMemo } from 'react';

import { useDomainOptions } from '../domains';
import { Domains } from '../domains/types';

export function useInputValidation(args?: { options?: Record<string, any> }) {
  const { options } = useDomainOptions(
    Domains.VALIDAZIONE_ATTRIBUTI,
    undefined,
    args?.options,
  );

  return useMemo(
    () =>
      options?.reduce(
        (
          acc: Record<string, { value: RegExp; message: string }>,
          { value, data },
        ) => ({
          ...acc,
          [value]: {
            value: new RegExp(data?.pattern),
            message: data?.tooltip,
          },
        }),
        {},
      ),
    [options],
  );
}
